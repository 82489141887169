import React from 'react'
import Header from '../../common/Header'
import Footer from '../../common/Footer'

export default function Contact() {
    return (
        <>
            <Header />
            <section className="blog-area pt182 min570">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="heading-banner-title">
                                            <h4 className="text-center cntcth4">Contact Us</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-md-4 col-xs-12">
                                        <figure className="infodetail">
                                            <i className="fa fa-map-marker"></i>
                                            <figcaption>
                                                <h4>JK Bond Forever Program  Center</h4>
                                                <address>
                                                    C/o Evolve Brands Pvt Ltd
                                                    <br />
                                                    249 G, AIHP Tower,

                                                    Level 2, Wing A,<br />
                                                    Udyog Vihar Phase 4,
                                                    Gurugram,122015


                                                </address>
                                            </figcaption>
                                        </figure>
                                    </div>
                                    <div className="col-md-4 col-xs-12">
                                        <figure className="infodetail">
                                            <i className="fa fa-phone"></i>
                                            <figcaption>
                                                <h4>Timing</h4>
                                                <address>
                                                    09:30 AM to 5:30 PM (Mon-Sat)<br />
                                                    +91 9289244966
                                                </address>
                                            </figcaption>
                                        </figure>
                                    </div>
                                    <div className="col-md-4 col-xs-12">
                                        <figure className="infodetail">
                                            <i className="fa fa-envelope-o"></i>
                                            <figcaption>
                                                <h4 className="mail">Email</h4>
                                                <address>
                                                    info@jk-bondforever.com
                                                </address>
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
