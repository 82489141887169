import React, { useState, useEffect } from 'react'
import bond_forever from '../../../assets/img/bond_forever.png'
import axios from 'axios'
import * as Constants from '../../../Constants/index'
import { useFormik } from 'formik'
import swal from 'sweetalert';
export default function EnrollmentForm() {
    const [loading, setLoading] = useState(false)
    const [stateFetch, setStateFetch] = useState([])
    const [districtFetch, setDistrictFetch] = useState([])
    const [cityFetch, setCityFetch] = useState([])
    const searchFormik = useFormik({
        initialValues: {
            firmName: '',
            name: '',
            mobile: '',
            email: '',
            region: '',
            state: '',
            stateId: 0,
            district: '',
            districtId: 0,
            city: '',
            cityId: 0,
            pincode: '',
            firmAddress: ''


        },
        validate: (data) => {
            let errors = {};
            if (!data.firmName) {
                errors.firmName = 'Firm name is required'
            }
            if (!data.name) {
                errors.name = 'Name is required'
            }
            if (!data.mobile) {
                errors.mobile = 'Mobile no. is required';
            } else if (!/^\d+$/.test(data.mobile)) {
                errors.mobile = 'Mobile no. must be numeric';
            } else if (data.mobile.length !== 10) {
                errors.mobile = 'Mobile no. must be 10 digits';
            }
            if (data.email) {
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                    errors.email = 'Invalid email address.'
                }
            }
            if (!data.region) {
                errors.region = 'Region is required'
            }
            if (!data.state) {
                errors.state = 'State is required'
            }
            if (!data.district) {
                errors.district = 'District is required'
            }
            if (!data.city) {
                errors.city = 'City is required'
            }
            if (!data.pincode) {
                errors.pincode = 'Pincode no. is required';
            } else if (!/^\d+$/.test(data.mobile)) {
                errors.pincode = 'Pincode no. must be numeric';
            } else if (data.pincode.length !== 6) {
                errors.pincode = 'Pincode no. must be 6 digits';
            }
            if (!data.firmAddress) {
                errors.firmAddress = 'Firm address is required'
            }
            console.log(errors)
            return errors;
        },
        onSubmit: (data) => {
            console.log('registration data', data)
            handleRegistration(data);
        },
    });
    const isSearchFormFieldValid = (name) =>
        !!(searchFormik.touched[name] && searchFormik.errors[name]);
    const getSearchFormErrorMessage = (name) => {
        return (
            isSearchFormFieldValid(name) && (
                <small className="lending-error">{searchFormik.errors[name]}</small>
            )
        );
    };

    useEffect(() => {
        stateService()

    }, [])

    // state api call
    const stateService = async () => {
        setLoading(true);

        await axios.get(`${Constants.baseUrl}master/geography/state?sortBy=name&sortAsc=true&limit=1000&page=1`, {
            headers: {
                "Accept-Language": localStorage.getItem("langauge"),
            }
        }).then((res) => {
            const dt = res.data;
            if (dt.result === 'success') {
                setLoading(false)
                setStateFetch(dt.output.results)
            } else if (dt === 'error' || dt.result === "dialog") {
                setLoading(false)
            }
        }).catch((err) => {
            setLoading(false);
        });
    }

    const handleStateChange = (e) => {
        const selectedId = e.target.value;
        setDistrictFetch([]);
        setCityFetch([]);
        searchFormik.setValues({
            ...searchFormik.values,
            district: '',
            districtId: 0,
            city: '',
            cityId: 0
        });

        // Clear all fields if selectedId is empty
        if (selectedId === '') {
            searchFormik.setValues({
                ...searchFormik.values,
                state: '',
                stateId: 0,
                district: '',
                districtId: 0,
                city: '',
                cityId: 0
            });
        } else {
            districtService(selectedId);
        }
    };

    const districtService = async (stateId) => {
        setLoading(true);

        await axios.get(`${Constants.baseUrl}master/geography/state/${stateId}`, {
            headers: {
                "Accept-Language": localStorage.getItem("langauge"),
            }
        }).then((res) => {
            const dt = res.data;
            if (dt.result === 'success') {
                setDistrictFetch(dt.output.districts)
                searchFormik.setValues({
                    ...searchFormik.values,
                    state: dt.output.name,
                    stateId: dt.output.id,
                });
                setLoading(false)
            } else if (dt === 'error' || dt.result === "dialog") {
                setLoading(false)
            }
        }).catch((err) => {
            setLoading(false);
        });
    }
    const handleDistrictChange = (event) => {
        const selectedId = event.target.value
        setCityFetch([])
        searchFormik.setValues({
            ...searchFormik.values,
            city: '',
            cityId: 0,
        });
        if (selectedId === '') {
            setCityFetch([])
            searchFormik.setValues({
                ...searchFormik.values,
                district: '',
                districtId: 0,
                city: '',
                cityId: 0,
            });

        } else {
            cityService(selectedId);
        }
    };

    const cityService = async (cityId) => {
        await axios.get(`${Constants.baseUrl}master/geography/district/${cityId}`, {
            headers: {
                "Accept-Language": localStorage.getItem("langauge"),
            }
        }).then((res) => {
            const dt = res.data;
            if (dt.result === 'success') {
                setLoading(false)
                setCityFetch(dt.output.cities)
                searchFormik.setValues({
                    ...searchFormik.values,
                    district: dt.output.name,
                    districtId: dt.output.id
                });
            } else if (dt === 'error' || dt.result === "dialog") {
                setLoading(false)
            }
        }).catch((err) => {
            setLoading(false);
        });
    }

    const handleCityChange = (e) => {
        const selectedCityName = e.target.value;
        const city = cityFetch.find(city => city.name === selectedCityName);
        searchFormik.setValues({
            ...searchFormik.values,
            city: selectedCityName,
            cityId: city?.id,
        });
    };

    const handleRegistration = async (data) => {
        await axios.post(`${Constants.baseUrl}auth/register`, {
            name: data.name,
            email: data.email,
            mobile: data.mobile,
            alternateMobile: '',
            whatsapMobile: '',
            gst: '',
            gstUrl: '',
            gender: '',
            userUniqid: '',
            dob: '',
            password: '',
            status: '',
            userModelKYC: {
                aadharMobile: '',
                aadharNumber: '',
                aadharFrontUrl: '',
                aadharBackUrl: '',
                aadharVerify: 0,
                aadharVerifiedBy: '',
                aadharVerifiedOn: '',
                panNumber: '',
                panFrontUrl: '',
                panVerify: 0,
                panVerifiedBy: '',
                panVerifiedOn: ''
            },
            addressWork: {
                workAddress1: data.firmAddress,
                workAddress2: '',
                workAddress3: '',
                workCity: data.city,
                workCityId: data.cityId,
                workState: data.state,
                workStateId: data.stateId,
                workdistrict: data.district,
                workdistrictId: data.districtId,
                workPincode: data.pincode,
                workCountry: ''
            },
            addressModelPerm: {
                permAddress1: '',
                permAddress2: '',
                permAddress3: '',
                permCity: '',
                permCityId: 0,
                permState: '',
                permStateId: 0,
                permdistrict: '',
                permdistrictId: 0,
                permPincode: '',
                permCountry: ''
            },
            sisterConcern: {
                sister1: '',
                sister2: '',
                sister3: '',
                sister4: '',
                sister5: '',
                sister6: '',
                sister7: '',
                sister8: '',
                sister9: ''
            },
            bankDetail: {
                bankAccountNo: '',
                accountType: '',
                ifsc: '',
                beneficiary: '',
                bankName: '',
                holderName: '',
                branchName: '',
                cancelChk: '',
                upiId: '',
                upiIdScreen: ''
            },
            parentId: 0,
            parentType: '',
            userType: 'MEMBER',
            identityType: '',
            identityNumber: '',
            identityCardBackUrl: '',
            identityCardFrontUrl: '',
            getsStateId: '',
            getsDistrictId: '',
            getsCityId: '',
            retailerFirmName: '',
            firmName: data.firmName,
            firmSapCode: '',
            spocName: '',
            spocMobile: '',
            spocEmail: '',
            zone: '',
            parentName: '',
            parentMobile: '',
            parentEmail: '',
            parentZone: data.region,
            parentState: '',
            parentCity: '',
            profilePic: '',
            monthlyConsumptionAmount: 0,
            monthlyConsumptionCarton: 0,
            officialemail: '',
            officialMobile: ''
        },
            {
                headers: {
                    "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    Company: Constants.company,
                    program: Constants.program
                },
            }).then((res) => {
                const dt = res.data
                if (dt.result === 'success') {
                    setLoading(false)
                    searchFormik.resetForm()
                    swal({
                        text: 'Sucess',
                        icon: 'success',
                        buttons: false,
                        timer: 2000
                    });
                } else if (dt.result === 'error' || dt.result === "dialog") {
                    setLoading(false)
                    swal({
                        text: dt.msg,
                        icon: 'error',
                        buttons: false,
                        timer: 2000
                    });
                }
            })
    }

    return (
        <>
            <div class="hero_cover_one mt128" id="myDiv">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 col-sm-8 col-xs-12">
                            <div class="innerdiv row">
                                <div class="col-md-7 nopaddleft col-sm-7 col-xs-12 hidden-xs">
                                    <figure class="combo">
                                        <img src={bond_forever} class="img-responsive" alt="get color combo pack" />
                                    </figure>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-12">
                            <div class="enquiryForm">
                                <form onSubmit={searchFormik.handleSubmit}>
                                    <div class="regwindow">
                                        <h1 class="contacttag">ENROLLMENT FORM</h1>
                                        <div class="form-group landing-form-group">
                                            <div class="input-group">
                                                <span class="input-group-addon">
                                                    <span class="fa fa-user" aria-hidden="true"></span>
                                                </span>
                                                <input type="text" placeholder="Firm Name *" class="form-control frm" id='firmName' value={searchFormik.values.firmName} onChange={searchFormik.handleChange} />
                                                {getSearchFormErrorMessage('firmName')}
                                            </div>
                                        </div>
                                        <div class="form-group landing-form-group">
                                            <div class="input-group">
                                                <span class="input-group-addon">
                                                    <span class="fa fa-user" aria-hidden="true"></span>
                                                </span>
                                                <input type="text" placeholder="Your Name *" class="form-control frm" id='name' value={searchFormik.values.name} onChange={searchFormik.handleChange} />
                                                {getSearchFormErrorMessage('name')}
                                            </div>
                                        </div>

                                        <div class="form-group landing-form-group">
                                            <div class="input-group">
                                                <span class="input-group-addon">
                                                    <span class="fa fa-mobile" aria-hidden="true"></span>
                                                </span>
                                                <input type="text" class="form-control frm" placeholder="Mobile No. *" id='mobile' maxLength='10' value={searchFormik.values.mobile} onChange={searchFormik.handleChange} onKeyPress={(e) => {
                                                    const isNumeric = /^[0-9]*$/;
                                                    if (!isNumeric.test(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }} />
                                                {getSearchFormErrorMessage('mobile')}
                                            </div>
                                        </div>
                                        <div class="form-group landing-form-group">
                                            <div class="input-group">
                                                <span class="input-group-addon">
                                                    <span class="fa fa-envelope-o" aria-hidden="true"></span>
                                                </span>
                                                <input type="text" class="form-control frm" placeholder="E-Mail ID (optional)" id='email' value={searchFormik.values.email} onChange={searchFormik.handleChange} />
                                                {getSearchFormErrorMessage('email')}
                                            </div>
                                        </div>
                                        <div class="form-group landing-form-group">
                                            <div class="input-group">
                                                <span class="input-group-addon">
                                                    <span class="fa fa-address-card" aria-hidden="true"></span>
                                                </span>
                                                <select class="form-control frm" id='region' value={searchFormik.values.region} onChange={searchFormik.handleChange}>
                                                    <option value=''>Select Region * </option>
                                                    <option value='EAST'>East</option>
                                                    <option value='NORTH'>North</option>
                                                    <option value='SOUTH'>South</option>
                                                    <option value='WEST'>West</option>
                                                </select>
                                                {getSearchFormErrorMessage('region')}
                                            </div>
                                        </div>

                                        <div class="form-group landing-form-group">
                                            <div class="input-group">
                                                <span class="input-group-addon">
                                                    <span class="fa fa-address-card" aria-hidden="true"></span>
                                                </span>
                                                <select class="form-control frm" id='state' onChange={handleStateChange}>
                                                    <option value=''>Select State *</option>
                                                    {stateFetch.map((item, index) => {
                                                        return (
                                                            <>
                                                                <option key={index} value={item?.id}>{item?.name}</option>
                                                            </>
                                                        )
                                                    })}
                                                </select>
                                                {getSearchFormErrorMessage('state')}
                                            </div>
                                        </div>

                                        <div class="form-group landing-form-group">
                                            <div class="input-group">
                                                <span class="input-group-addon">
                                                    <span class="fa fa-address-card" aria-hidden="true"></span>
                                                </span>
                                                <select class="form-control frm" id='district' onChange={handleDistrictChange}>
                                                    <option value=''>Select District *</option>
                                                    {districtFetch.map((item, index) => {
                                                        return (
                                                            <>
                                                                <option key={index} value={item?.id}>{item?.name}</option>
                                                            </>
                                                        )
                                                    })}
                                                </select>
                                                {getSearchFormErrorMessage('district')}
                                            </div>
                                        </div>

                                        <div class="form-group landing-form-group">
                                            <div class="input-group">
                                                <span class="input-group-addon">
                                                    <span class="fa fa-address-card" aria-hidden="true"></span>
                                                </span>
                                                <select class="form-control frm" id='city' onChange={handleCityChange}>
                                                    <option value=''>Select City *</option>
                                                    {cityFetch.map((item, index) => {
                                                        return (
                                                            <>
                                                                <option key={index} value={item?.name}>{item?.name}</option>
                                                            </>
                                                        )
                                                    })}
                                                </select>
                                                {getSearchFormErrorMessage('city')}
                                            </div>
                                        </div>
                                        <div class="form-group landing-form-group">
                                            <div class="input-group">
                                                <span class="input-group-addon">
                                                    <span class="fa fa-mobile" aria-hidden="true"></span>
                                                </span>
                                                <input type="text" class="form-control frm" placeholder="Pincode No. *" id='pincode' value={searchFormik.values.pincode} onChange={searchFormik.handleChange} onKeyPress={(e) => {
                                                    const isNumeric = /^[0-9]*$/;
                                                    if (!isNumeric.test(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }} />
                                                {getSearchFormErrorMessage('pincode')}
                                            </div>
                                        </div>
                                        <div class="form-group landing-form-group">
                                            <div class="input-group">
                                                <span class="input-group-addon">
                                                    <span class="fa fa-user" aria-hidden="true"></span>
                                                </span>
                                                <input type="text" placeholder="Firm Address *" class="form-control frm" id='firmAddress' value={searchFormik.values.firmAddress} onChange={searchFormik.handleChange} />
                                                {getSearchFormErrorMessage('firmAddress')}
                                            </div>
                                        </div>
                                        <div class="landing-submit">
                                            <button name="button" class="btn btn-primary landingsubmit" type='submit'>Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
