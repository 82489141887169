import React from 'react'

export default function Contact() {
    return (
        <>
            <section class="blog-area about-programp">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="all-single-blog">
                                <div class="row">
                                    <div class="col-md-12">

                                        <div class="heading-banner-title">
                                            <h4><span>Contact Us</span></h4>
                                        </div>
                                    </div>
                                </div>


                                <div class="row clearfix">
                                    <div class="col-md-4 col-xs-12">
                                        <figure class="infodetail" style={{ minHeight: '245px' }} >
                                            <i class="fa fa-map-marker"></i>
                                            <figcaption>
                                                <h4>JK-Bondforever Program</h4>
                                                <address>
                                                    Evolve Brands Pvt Ltd <br />
                                                    249 G, Level 2, Wing A,<br /> AIHP Tower, Udyog Vihar Phase 4 , <br />Gurgaon,122015
                                                </address>
                                            </figcaption>
                                        </figure>
                                    </div>
                                    <div class="col-md-4 col-xs-12">
                                        <figure class="infodetail" style={{ minHeight: '245px' }}>
                                            <i class="fa fa-phone"></i>
                                            <figcaption>
                                                <h4>Timing</h4>
                                                <address>
                                                    09:30 AM to 5:30 PM (Mon-Sat)<br />
                                                    <a href="tel:0000000000">+91 0000000000</a>
                                                </address>
                                            </figcaption>
                                        </figure>
                                    </div>
                                    <div class="col-md-4 col-xs-12">
                                        <figure class="infodetail" style={{ minHeight: '245px' }}>
                                            <i class="fa fa-envelope-o"></i>
                                            <figcaption>
                                                <h4 class="mail">E-Mail ID</h4>
                                                <address>
                                                    info@jk-bondforever.com
                                                </address>
                                            </figcaption>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}
