import React, { useState, useEffect } from 'react'
import logo_mobile from '../../../assets/img/logo/logo-mobile.png'
import logo2 from '../../../assets/img/logo/logo2.png'
import { Link } from "react-scroll";
import Footer from '../../common/Footer'
import EnrollmentForm from './EnrollmentForm'
import ProgramStages from './ProgramStages'
import AboutJKBondForever from './AboutJKBondForever'
import AboutJKPaper from './AboutJKPaper'
import Contact from './Contact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
export default function LandingPage() {
    const [isVisible, setIsVisible] = useState(false);


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        const scrollTop = window.pageYOffset;

        if (scrollTop > 200) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    return (
        <>
            <div className="wrapper home-2">
                <header>
                    <div className="header-container header-sticky sticky">
                        <div className="header-top-area">
                            <div className="container">
                                <div class="row">
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                        <div class="header-top-menu text-left">
                                            <ul>
                                                <li class=""><span><i class="fa fa-envelope-o"></i>info@jk-bondforever.com</span></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="col-md-8 col-sm-8  text-right">
                                        <div class="header-top-menu">
                                            <ul>
                                                <li class="support"><span><i class="fa fa-phone"></i> <a href="#">+91 0000000000</a> </span></li>
                                                <li class="support hidden-xs"><span> <i class="fa fa-clock-o"></i> 09.30 AM – 5.30 PM (Mon to Fri)</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="header-middel-area hidden-xs">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-2 col-sm-2 col-xs-6">
                                        <div className="logo logo1">
                                            <Link to=""><img src={logo_mobile} alt="" /></Link>
                                        </div>
                                    </div>

                                    <div className="col-md-8 col-sm-8 col-xs-12 hidden-xs">
                                        <div className="main-menu-area">
                                            <nav>
                                                <ul className="main-menu">
                                                    <li><Link to="program_stages" smooth={true}
                                                        duration={400}
                                                        offset={-120}
                                                    >Registration Steps</Link></li>
                                                    <li><Link to="about_jk_bond" smooth={true}
                                                        duration={400}
                                                        offset={-120}>About JK Bond Forever</Link></li>
                                                    <li><Link to="about_jk_paper" smooth={true}
                                                        duration={400}
                                                        offset={-120}>About JK Paper</Link></li>
                                                    <li><Link to="contact" smooth={true}
                                                        duration={400}
                                                        offset={-120}>Contact</Link></li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>

                                    <div className="col-md-2 col-sm-4 col-xs-6 text-right">
                                        <div className="logo1hafele">
                                            <Link to=""><img src={logo2} alt="" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="mobile-menu-area hidden-sm hidden-md hidden-lg">
                            <div className="container">
                                <div className="row">
                                    <div className="mobile_logo">
                                        <Link to="#"><img src="img/logo/logo-mobile.png" alt="" /></Link>
                                    </div>
                                    <div className="col-xs-12">
                                        <div className="mobile-menu">
                                            <nav>
                                                <ul className="main-menu">
                                                    <li><Link to="/registraion_steps">Registration Steps</Link></li>
                                                    <li><Link to="/about_jk_bond_forever">About JK Bond Forever</Link></li>
                                                    <li><Link to="/about_jk_paper">About JK Paper</Link></li>
                                                    <li><Link to="/contact">Contact</Link></li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </header>
            </div>
            <div >
                <EnrollmentForm />
            </div>
            <div id="program_stages">
                <ProgramStages />
            </div>
            <div id="about_jk_bond">
                <AboutJKBondForever />
            </div>
            <div id="about_jk_paper">
                <AboutJKPaper />
            </div>
            <div id="contact">
                <Contact />
            </div>
            <Footer />
            <Link id="scrollUp" to="" className={`scroll-to-top-button ${isVisible ? 'visible' : 'hidden'}`} style={{ position: 'fixed', zIndex: '2147483647' }} onClick={scrollToTop}><FontAwesomeIcon icon={faChevronUp} /></Link>

        </>
    )
}
