import React from 'react'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
export default function AboutJkPaper() {
    return (
        <>
            <Header />
            <section className="blog-area pt182 min570">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="heading-banner-title">

                                            <h4 className="text-center cntcth4"><span>About JK Paper</span></h4>
                                        </div>
                                    </div>
                                </div>


                                <div className="row clearfix">
                                    <div className="col-md-12 col-xs-12 aboutv">
                                        <p>
                                            Established in 1962, JK Paper is the leading player in Office papers, Coated papers and Packaging boards. It has two integrated Pulp and Paper Mills viz; Unit JKPM at Rayagada (Odisha) near the Eastern coast of India and Unit CPM, Songadh (Gujarat) on the Western coast. In August 2018, JK Paper has acquired Sirpur Paper Mills Ltd– located in Telangana. With this acquisition, the company’s capacity is now 591,000 TPA. From being the pioneer of branded paper in India, the Company today is a leading exporter of Branded Copier Papers and Packaging Boards exporting to over 60 countries covering USA, Middle-East, South-east Asia, SAARC and African countries.
                                            <br />
                                            JK Paper sells its products through an extensive distribution network of 229 trade partners, 18 depots & 4 regional marketing offices covering nearly 4,000 dealers.

                                        </p>

                                        <ul className="luckydrawlist">
                                            <li>
                                                <strong>Office Papers </strong>JK Cedar , JK Copier, JK Easy Copier, JK Sparkle,JK Copier Plus, JK Excel Bond, JK Sparkle, JK CMax, JK Max, JK Copier A5, JK Sparkle A5, JK Copier Slims
                                            </li>
                                            <li><strong>Packaging Boards</strong> TuffCote, JK Ultima ,JK TuffPac , JK IV Board, JK Endura, JK Tuffcup</li>
                                            <li>
                                                <strong>Printing &amp; Writing Papers-</strong>  JK Cote , JK Ledger, JK SHB, JK Evervite, JK Finesse ,JK Elektra, JK Lumina, JK Ultraprint, JK Esay Draw, JK Buff File Board, JK Braille
                                            </li>
                                            <li>
                                                <strong>Specialty Papers</strong>   MICR Cheque paper, Parchment, Cedar digital, JK Envelope, JK Carry, JK Tally, JK Oleoff, JK Ecosip
                                            </li>
                                        </ul>

                                        <p>As part of a larger commitment &amp; respect for the environment, the Company has sustained its efforts for increasing the green cover within 200 Km area from the plant sites at Jaykaypur, Distt. Rayagada (Odisha) and Fort Songadh (Gujarat). It has planted close 160,000 Hectare of land under this programme. Most of these are planted with Eucalyptus, Casurina and Subabul clones as per requirement under the project of social farm forestry.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
