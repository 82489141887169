import React from 'react'
import logo_mobile from '../../assets/img/logo/logo-mobile.png'
import logo2 from '../../assets/img/logo/logo2.png'
import { Link } from 'react-router-dom'
export default function Header() {
    const userType = localStorage.getItem('user_type')
    const userName = localStorage.getItem('user_name')

    const handleLogout = () => {
        localStorage.clear();
    };
    return (
        <>
            <div className="wrapper home-2">
                <header>
                    <div className="header-container header-sticky sticky">
                        <div className="header-top-area">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-5 col-sm-5 col-xs-12 text-left">
                                        <div className="header-top-menu text-left">
                                            <ul>
                                                <li className=""><span><i className="fa fa-envelope-o"></i> info@jk-bondforever.com</span></li>
                                                <li className="support"><span><i className="fa fa-phone"></i> <Link to="tel:+91 9289244966">+91 9289244966</Link> </span></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-sm-7 col-xs-12  text-right">
                                        <div className="header-top-menu">
                                            <ul>
                                                <li className="support hidden-xs"><span> <i className="fa fa-clock-o"></i> 09.30 AM – 05.30 PM </span></li>
                                                
                                                {userType ? <>
                                                <li className="account"><span><i className="fa fa-user"></i> Welcome {userName} </span></li>
                                                <li className="account" onClick={handleLogout}><Link to="/" className="navigator"><span><i className="fa fa-sign-in"></i> Logout</span> </Link></li>
                                                </>
                                                :
                                                <><li className="account"><Link to="/" className="navigator"><i className="fa fa-sign-in"></i> Login </Link></li></>}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="header-middel-area hidden-xs">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-2 col-sm-2 col-xs-6">
                                        <div className="logo logo1">
                                            <Link to=""><img src={logo_mobile} alt="" /></Link>
                                        </div>
                                    </div>

                                    <div className="col-md-8 col-sm-8 col-xs-12 hidden-xs">
                                        <div className="main-menu-area">
                                            <nav>
                                                <ul className="main-menu">
                                                    <li><Link to={`${userType === 'RO' || userType === 'HO' ? '/dashboard' : '/'}`}>Home</Link></li>
                                                    {userType === 'HO' || userType === 'RO' ? <li><Link to="/approve_member">Approve Member</Link></li> : ''}
                                                    {!userType ? <li><Link to="/about_jk_paper">About JK Paper</Link></li> : ''}
                                                    {!userType ? <li><Link to="/about_jk_bond_forever">About JK Bond Forever</Link></li> : ''}
                                                    {!userType ? <li><Link to="#">Product Catalog</Link></li> : ''}
                                                    <li><Link to="/contact">Contact</Link></li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>

                                    <div className="col-md-2 col-sm-4 col-xs-6 text-right">
                                        <div className="logo1hafele">
                                            <Link to=""><img src={logo2} alt="" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mobile-menu-area hidden-sm hidden-md hidden-lg">
                            <div className="container">
                                <div className="row">
                                    <div className="mobile_logo">
                                        <Link to="#"><img src="img/logo/logo-mobile.png" alt="" /></Link>
                                    </div>
                                    <div className="col-xs-12">
                                        <div className="mobile-menu">
                                            <nav>
                                                <ul className="main-menu">
                                                    <li><Link to="/">Home</Link></li>
                                                    <li><Link to="/about_jk_paper">About JK Paper</Link></li>
                                                    <li><Link to="/about_jk_bond_forever">About JK Bond Forever</Link></li>
                                                    <li><Link to="/product_catalog">Product Catalog</Link></li>
                                                    <li><Link to="/contact">Contact</Link></li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div >
                </header >
            </div >
        </>
    )
}
