import React, { useState, useEffect } from 'react'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import { Dialog } from 'primereact/dialog';
import '../../../assets/css/style.css'
import { useFormik } from 'formik';
import axios from 'axios';
import * as Constants from '../../../Constants/index'
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import noImage from '../../../assets/img/no image.png'
import ResponsivePagination from 'react-responsive-pagination';
import Oval from '../Loader/CircleLoade';
import moment from 'moment';

export default function ApproveMember() {
    let token = localStorage.getItem('token');
    const [loading, setLoading] = useState(false)
    const [regModal, setRegModal] = useState(false)
    const [approveRejectModal, setApproveRejectModal] = useState(false)
    const [results, setResults] = useState([])
    const [searchRecord, setSearchRecord] = useState()
    const [idData, setIdData] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState()
    const [limit] = useState(10)
    const [memberId, setMemberId] = useState()
    const [userSearch, setUserSearch] = useState('')

    const approveRejectFormik = useFormik({
        initialValues: {
            status: '',
            reason: '',
            remarks: ''
        },
        validate: (data) => {
            let errors = {};
            if (!data.status) {
                errors.status = 'Please select status';
            }
            if (data.status === 'REJECT') {
                if (data.reason === '') {
                    errors.reason = 'Please select reason';
                } else if (data.reason === 'OTHER' && data.remarks === '') {
                    errors.remarks = 'Please enter remarks'
                }
            }

            return errors;
        },
        onSubmit: (data) => {
            // console.log('status data', data)
            handleUpdateStatus(memberId, data);
        },
    });
    const isApproveRejectFieldValid = (name) =>
        !!(approveRejectFormik.touched[name] && approveRejectFormik.errors[name]);
    const getApproveRejectErrorMessage = (name) => {
        return (
            isApproveRejectFieldValid(name) && (
                <small className="app-rej-error">{approveRejectFormik.errors[name]}</small>
            )
        );
    };

    const handleClose = () => {
        setApproveRejectModal(false)
        approveRejectFormik.resetForm()
    }

    const handleApproveReject = (e) => {
        const selectedStatus = e.target.value;
        approveRejectFormik.setFieldValue('status', selectedStatus)
        if (selectedStatus === 'APPROVED') {
            approveRejectFormik.setValues({
                ...approveRejectFormik.values,
                status: selectedStatus,
                reason: '',
                remarks: ''
            });
        } else {
            // Handle other cases if needed
        }
    };

    const handleReasonStatus = (e) => {
        const selectedReason = e.target.value;

        approveRejectFormik.setFieldValue('reason', selectedReason)

        if (selectedReason !== 'OTHER') {
            approveRejectFormik.setValues({
                ...approveRejectFormik.values,
                reason: selectedReason,
                remarks: ''
            });
        } else {
            // Handle other cases if needed
        }
    };


    useEffect(() => {
        if (userSearch === '') {
            pendingRegistrationData('')
        } else {
            pendingRegistrationData(userSearch)
        }

    }, [currentPage])

    //pending registration data 
    const pendingRegistrationData = async (user) => {
        setLoading(true);
        const searchUserVal = user === '' ? '' : `name=${user}&`
        await axios.get(Constants.baseUrl + `user/manage/userByZoneId?${searchUserVal}userType=MEMBER&status=VERIFIED&sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${currentPage}`, {
            headers: {
                Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                appVersion: Constants.av,
                platform: Constants.pt,
                company: Constants.company,
                program: Constants.program,
            }
        }).then((res) => {
            const dt = res.data
            if (dt.result === 'success') {
                setResults(dt?.output?.results)
                setTotalPage(dt?.output?.totalPages);
                setLoading(false);
            } else if (dt.result === 'error' || dt.result === "dialog") {
                setLoading(false);
                setSearchRecord(dt?.output?.results)
                setTotalPage(dt?.output?.totalPages);
            }
        }).catch((err) => {
            setLoading(false);
        })
    };

    //show registraion details
    const showRegDetails = async (id) => {
        setLoading(true);
        await axios.get(Constants.baseUrl + `user/manage/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                appVersion: Constants.av,
                platform: Constants.pt,
                company: Constants.company,
                program: Constants.program,
            }
        }).then((res) => {
            const dt = res.data
            if (dt.result === 'success') {
                setIdData(dt?.output)
                setLoading(false);
                setRegModal(true)
            } else if (dt.result === 'error' || dt.result === "dialog") {
                setLoading(false);
                setRegModal(false)
            }
        }).catch((err) => {
            setLoading(false);
        })
    };

    //approve reject by id
    const handleSelect = async (id) => {
        await axios.get(Constants.baseUrl + `user/manage/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                appVersion: Constants.av,
                platform: Constants.pt,
                company: Constants.company,
                program: Constants.program,
            }
        }).then((res) => {
            const dt = res.data
            if (dt.result === 'success') {
                setMemberId(dt.output.id)
                setApproveRejectModal(true)
            } else if (dt.result === 'error' || dt.result === "dialog") {
                setApproveRejectModal(false)
            }
        }).catch((err) => {
            setLoading(false);
        })
    };

    //update status
    const handleUpdateStatus = async (id, data) => {
        await axios.post(Constants.baseUrl + `user/manage/updatestatus/${id}`, {
            status: data.status,
            reason: data.reason,
            remarks: data.remarks
        }, {
            headers: {
                Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                appVersion: Constants.av,
                platform: Constants.pt,
            }
        }).then((res) => {
            const dt = res.data
            if (dt.result === 'success') {
                setApproveRejectModal(false)
                approveRejectFormik.resetForm()
                pendingRegistrationData('')
                swal({
                    text: res.data.msg,
                    icon: 'success',
                    timer: 2000,
                    buttons: false,
                });


            } else if (dt.result === 'error' || dt.result === "dialog") {
                setLoading(false);

            }
        }).catch((err) => {
            setLoading(false);
        })
    };

    const handleSearchQuery = (event) => {
        setUserSearch(event.target.value)
        if (event.target.value === '') {
            pendingRegistrationData('')
            setSearchRecord()
        }
    }

    // console.log('idData', idData)

    return (
        <>
            {loading ? <Oval /> : null}
            <Header />
            <section className="blog-area pt182">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog rpt">
                                <div className="row clearfix callwell mb-15">
                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group has-float-label style-two">
                                            <label>Name / Mobile No.</label>
                                            <input className="form-control" placeholder="Name / Mobile No." type="text" value={userSearch} onChange={handleSearchQuery} />
                                        </div>
                                    </div>
                                    {/* <div className="col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group has-float-label style-two">
                                            <label>Create Date </label>
                                            <input autocomplete="off" className="form-control datepicker1" id="Fromdate" placeholder="DD/MM/YYYY" type="text" value="" />
                                        </div>
                                    </div> */}

                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group has-float-label style-two">
                                            <label className="invisible" style={{ width: '100%' }}>hidden</label>
                                            <input type="button" className="btn login_btn" value="Search" id="btnSearch" onClick={() => pendingRegistrationData(userSearch)} disabled={userSearch.trim() === ''} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="carrd1">
                                            <div className="card-header card-header-warning">
                                                <h4 className="card-title">Change Status</h4>
                                            </div>
                                            <div className="card-body table-responsive">
                                                <table className="table table-striped mb_01">
                                                    <thead>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th>Organization Name</th>
                                                            <th>Name</th>
                                                            <th>Mobile No.</th>
                                                            <th>Zone</th>
                                                            <th>State Name</th>
                                                            <th>Office City</th>
                                                            <th>Date of Registration</th>
                                                            <th>View</th>
                                                            <th>Approve / Reject</th>
                                                        </tr>
                                                    </thead>
                                                    {searchRecord === null ?
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="10" style={{ textAlign: 'center', fontSize: '16px' }}>No record found!</td>
                                                            </tr>
                                                        </tbody> :
                                                        <tbody>
                                                            {results && results.map((item, index) => {
                                                                const serialNumber = (currentPage - 1) * limit + index + 1;

                                                                const regData = item?.createDateTime ? moment(item.createDateTime, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY') : ''
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>{serialNumber}</td>
                                                                            <td>{item?.firmName}</td>
                                                                            <td>{item?.name}</td>
                                                                            <td>{item?.mobile}</td>
                                                                            <td>{item?.addressWork?.workZone}</td>
                                                                            <td>{item?.addressWork?.workState}</td>
                                                                            <td>{item?.addressWork?.workCity}</td>
                                                                            <td>{regData}</td>
                                                                            <td><span className="btn login_btn" onClick={() => showRegDetails(item.id)}><i className="fa fa-eye"></i> View</span></td>
                                                                            <td ><span className="btn login_btn" onClick={() => handleSelect(item.id)}> Approve / Reject</span></td>
                                                                            {/* <td><a href="#" className="btn reject_btn" onClick={() => setApproveRejectModal(true)}><i className="fa fa-times"></i> Reject</a></td> */}
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })}
                                                        </tbody>}
                                                </table>
                                                {totalPage > 1 && (
                                                    <ResponsivePagination
                                                        current={currentPage}
                                                        total={totalPage}
                                                        onPageChange={(page) => setCurrentPage(page)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className="card flex justify-content-center reg-modal">
                <Dialog showHeader={false} visible={regModal} position='top'
                    style={{ width: '85vw' }}>
                    <div className="">
                        <div className="modal-header">
                            <button type="button" className="close" onClick={() => setRegModal(false)}>X</button>
                        </div>
                        <div className="modal-body">
                            <section className="blog-area">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <div className="all-single-blog">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="heading-banner-title">
                                                        <h4 className="text-center mb25">Registration Details</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row clearfix">
                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label> Name <sup>*</sup></label>
                                                        <input className="form-control" placeholder="Name*" type="text" value={idData.name} disabled />
                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>Gender <sup>*</sup></label>
                                                        <div className="h34">
                                                            <label className="radio-inline">
                                                                <input type="radio" name="marital_status" value="Male" checked={idData.gender === 'Male'} disabled /> Male
                                                            </label>
                                                            <label className="radio-inline">
                                                                <input type="radio" name="marital_status" value="Famle" checked={idData.gender === 'Famle'} disabled /> Female
                                                            </label>
                                                            <label className="radio-inline">
                                                                <input type="radio" name="marital_status" value="Not Declared" checked={idData.gender === 'Not Declared'} disabled /> Others
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>D.O.B <sup>*</sup></label>
                                                        <input className="form-control datepickerdob" placeholder="DD/MM/YYYY *" type="text" value={idData.dob} disabled />
                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>Mobile No. <sup>*</sup></label>
                                                        <input className="form-control" id="txtmob" placeholder="Mobile No." type="text" value={idData.mobile} disabled />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        {/* <label className="checkbox-inline mb-5 cllrs">
                                                            <input className="form-control" id="checkedwhatsapp" type="checkbox" />
                                                            If Whatsapp No is same as Mobile No. <sup>*</sup>
                                                        </label> */}
                                                        <label>Whatsapp No. <sup>*</sup></label>
                                                        <input className="form-control" id="txtmobW" placeholder="Whatsapp Number" type="text" value={idData.whatsapMobile} disabled />
                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>E-Mail ID Personal  <sup>*</sup></label>
                                                        <input className="form-control" type="text" id="txtEmail" placeholder="E-Mail ID Personal *" value={idData.email} disabled />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        {/* <label className="checkbox-inline mb-5 cllrs">
                                                            <input className="form-control" id="checkedemail" type="checkbox" />
                                                            If Official E-Mail is same as Personal<sup>*</sup>
                                                        </label> */}
                                                        <label>Official E-Mail  <sup>*</sup></label>
                                                        <input className="form-control" type="text" placeholder="E-Mail ID Official *" id="txtEmailOfficial" disabled />
                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>Operational Region  <sup>*</sup></label>
                                                        <input className="form-control" type="text" placeholder="Region*" value={idData?.addressWork?.workZone} disabled />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-12 col-xs-12 hidden">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>Marital Status  <sup>*</sup></label>
                                                        <div className="h34">
                                                            <label className="radio-inline">
                                                                <input type="radio" name="marital_status" value="single" disabled /> Single
                                                            </label>
                                                            <label className="radio-inline">
                                                                <input type="radio" name="marital_status" value="married" disabled /> Married
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-xs-12">
                                                    <h4 className="midhead">Permanent Address  </h4>
                                                </div>

                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>House / Flat / Block No.  <sup>*</sup></label>
                                                        <input className="form-control" placeholder="House / Flat / Block No. *" id="txtpadd1" type="text" value={idData?.addressModelPerm?.permAddress1} disabled />
                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>Street / Colony / Locality Name <sup>*</sup></label>
                                                        <input className="form-control" placeholder="Street / Colony / Locality Name *" id="txtpadd2" type="text" value={idData?.addressModelPerm?.permAddress2} disabled />
                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>Landmark  </label>
                                                        <input className="form-control" placeholder="Landmark *" type="text" id="txtpadd3" value={idData?.addressModelPerm?.permAddress3} disabled />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>State  <sup>*</sup></label>
                                                        <input className="form-control" placeholder="Landmark *" type="text" id="txtpadd3" value={idData?.addressModelPerm?.permState} disabled />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>City <sup>*</sup></label>
                                                        <input className="form-control" placeholder="City *" id="PCity" type="text" value={idData?.addressModelPerm?.permCity} disabled />
                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>Pin Code <sup>*</sup></label>
                                                        <input className="form-control" placeholder="Pin Code *" id="txtppincode" type="text" value={idData?.addressModelPerm?.permPincode} disabled />
                                                    </div>
                                                </div>

                                                <div className="col-md-12 col-xs-12">
                                                    <h4 className="midhead">Delivery Address<span>*</span>  </h4>
                                                    {/* <label className="checkbox-inline mb-10 cllrs"><input type="checkbox" value="" /> If Delivery address is same as Permanent address</label> */}
                                                </div>

                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>House / Flat / Block No.  <sup>*</sup></label>
                                                        <input className="form-control" placeholder="House / Flat / Block No. *" type="text" id="txtdadd1" value={idData?.addressWork?.workAddress1} disabled />
                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>Street / Colony / Locality Name <sup>*</sup></label>
                                                        <input className="form-control" placeholder="Street / Colony / Locality Name *" type="text" id="txtdadd2" value={idData?.addressWork?.workAddress2} disabled />
                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>Landmark  </label>
                                                        <input className="form-control" placeholder="Landmark  *" type="text" id="txtdadd3" value={idData?.addressWork?.workAddress3} disabled />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>State  <sup>*</sup></label>
                                                        <input className="form-control" placeholder="Landmark  *" type="text" id="txtdadd3" value={idData?.addressWork?.workState} disabled />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>City <sup>*</sup></label>
                                                        <input className="form-control" placeholder="City *" type="text" id="DCity" value={idData?.addressWork?.workCity} disabled />
                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>Pin Code <sup>*</sup></label>
                                                        <input className="form-control" placeholder="Pin Code *" type="text" id="txtdpincode" value={idData?.addressWork?.workPincode} disabled />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row clearfix mt-15">
                                                <div className="col-md-12 col-xs-12">
                                                    <h4 className="midhead">Upload ID Proof</h4>
                                                </div>

                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>Upload Your Photo <sup>*</sup></label>
                                                        <div className="h34">
                                                            <Link to={idData?.profilePic} target="_blank">
                                                                <img src={idData?.profilePic} className="docp" title="View Document" />
                                                            </Link>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>View Aadhar Card <sup>*</sup></label>
                                                        <div className="h34">
                                                            <Link to={idData?.userModelKYC?.aadharFrontUrl} target="_blank">
                                                                <img src={idData?.userModelKYC?.aadharFrontUrl} className="docp" title="View Document" />
                                                            </Link>
                                                        </div>

                                                        <span className="ff">Front Copy</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>View Aadhar Card <sup>*</sup></label>
                                                        <div className="h34">
                                                            <Link to={idData?.userModelKYC?.aadharFrontUrl} target="_blank">
                                                                <img src={idData?.userModelKYC?.aadharBackUrl} className="docp" title="View Document" />
                                                            </Link>
                                                        </div>
                                                        <span className="ff">Back Copy</span>
                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>Aadhar  No. <sup>*</sup></label>
                                                        <input className="form-control" placeholder="Aadhar No." type="text" value={idData?.userModelKYC?.aadharNumber} disabled />
                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label> PAN No. </label>
                                                        <input className="form-control" id="txtPan" placeholder="PAN No." type="text" value={idData?.userModelKYC?.panNumber} disabled />

                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>View PAN Card </label>
                                                        <div className="h34">
                                                            <Link to={idData?.userModelKYC?.panFrontUrl} target="_blank">
                                                                <img src={idData?.userModelKYC?.panFrontUrl} className="docp" title="View Document" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label> GST No.  </label>
                                                        <input className="form-control" id="txtGst" placeholder="GST Number" type="text" value={idData?.gst} disabled />

                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>View GST Photo </label>
                                                        <div className="h34">
                                                            {idData?.gstUrl ? (
                                                                <Link to={idData?.gstUrl} target="_blank">
                                                                    <img src={idData?.gstUrl} className="no-image" title="View Document" alt='' />
                                                                </Link>
                                                            ) : (
                                                                <img src={noImage} className="no-image" title="View Document" alt='' />

                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 col-xs-12">
                                                    <h4 className="midhead">Brands Consumption Details</h4>
                                                </div>
                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>Folio </label>
                                                        <input className="form-control" placeholder="Folio" type="text" value={idData?.monthlyConsumptionFolio} disabled/>
                                                    </div>
                                                </div> <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>Carton  </label>
                                                        <input className="form-control" placeholder="Carton" type="text" value={idData?.monthlyConsumptionCarton} disabled/>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-12 col-xs-12">
                                                    <div className="form-group has-float-label style-two">
                                                        <label>Brands Consumed  </label>
                                                        <input className="form-control" placeholder="Brands Consumed" type="text" value={idData?.brandConsumed} disabled/>
                                                    </div>
                                                </div>



                                                {/* <div className="col-md-12 col-sm-12 col-xs-12 mt-15 mb-15"> 
                                                    <div className="text-center">
                                                        <input type="submit" className="btn login_btn" value="Submit" />
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </Dialog>
            </div>

            <div className="card flex justify-content-center">
                <Dialog showHeader={false} visible={approveRejectModal} position='top' style={{ width: '50vw' }} onHide={handleClose}>

                    <div className="modal-content pop_content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" onClick={handleClose}>X</button>
                            <h4 className="modal-title">Approve / Reject</h4>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <form onSubmit={approveRejectFormik.handleSubmit}>
                                    <div className="col-md-8 col-md-offset-2">
                                        <div className="form-group">
                                            <label>Select Status<span style={{ color: 'red' }}>*</span></label>
                                            <select className="form-control2" id='status' value={approveRejectFormik.values.status} onChange={handleApproveReject}>
                                                <option value=''>Select Status</option>
                                                <option value="APPROVED">Approve</option>
                                                <option value="REJECT">Reject</option>
                                            </select>
                                            {getApproveRejectErrorMessage('status')}
                                        </div>
                                        {approveRejectFormik.values.status === 'REJECT' ?
                                            <div className="form-group">
                                                <label>Select Reason<span style={{ color: 'red' }}>*</span></label>
                                                <select className="form-control2" id='reason' value={approveRejectFormik.values.reason} onChange={handleReasonStatus}>
                                                    <option value=''>Select Reason</option>
                                                    <option value="Address Mismatch">Address Mismatch</option>
                                                    <option value="2">DOB mismatch</option>
                                                    <option value="3">Below 18 years</option>
                                                    <option value="4">Wrong ID Proof uploaded</option>
                                                    <option value="5">ID Proof not clear/uploaded</option>
                                                    <option value="6">GST Number Mismatch</option>
                                                    <option value="7">GST Document not clear/uploaded</option>
                                                    <option value="8">Aadhar card Number Mismatch</option>
                                                    <option value="9">PAN  Number Mismatch</option>
                                                    <option value="10">PAN Document not clear/uploaded</option>
                                                    <option value='OTHER'>Other</option>
                                                </select>
                                                {getApproveRejectErrorMessage('reason')}
                                            </div> : ''}
                                        {approveRejectFormik.values.status === 'REJECT' && approveRejectFormik.values.reason === 'OTHER' ?
                                            <div className="form-group">
                                                <label>Enter Remarks<span style={{ color: 'red' }}>*</span></label>
                                                <textarea className="form-control txtreason" cols="60" rows="4" placeholder="Enter your remarks here" id='remarks' value={approveRejectFormik.values.remarks} onChange={approveRejectFormik.handleChange}></textarea>
                                                {getApproveRejectErrorMessage('remarks')}
                                            </div> : ''}
                                        {approveRejectFormik.values.status !== '' ?
                                            < div className="text-center">
                                                <input type="submit" className="btn login_btn" value="Submit" />
                                            </div> : ''}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div >
                </Dialog >
            </div >
            <Footer />
        </>
    )
}
