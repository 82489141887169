import React, { useState, useEffect } from 'react'
import Header from '../../common/Header'
import { useFormik } from 'formik';
import '../../../assets/css/style.css'
import axios from 'axios';
import * as Constants from '../../../Constants/index'
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
export default function Registration() {
    let token = localStorage.getItem('token');
    const [loading, setLoading] = useState(false)
    const [stateFetch, setStateFetch] = useState([])
    const [districtFetch, setDistrictFetch] = useState([])
    const [delDistrictFetch, setDelDistrictFetch] = useState([])
    const [cityFetch, setCityFetch] = useState([])
    const [delCityFetch, setDelCityFetch] = useState([])
    const [selectedOption, setSelectedOption] = useState('male');


    const searchFormik = useFormik({
        initialValues: {
            name: '',
            dob: '',
            mobile: '',
            checkedwhatsapp: false,
            whatsappMob: '',
            email: '',
            checkedEmail: false,
            officialEmail: '',
            //permanent address
            perHouseNo: '',
            perLocalityName: '',
            perLandmark: '',
            perState: '',
            perStateId: 0,
            perDistrict: '',
            perDistrictId: 0,
            perCity: '',
            perCityId: 0,
            perPincode: '',
            checkedAddress: false,
            //delivery address
            delHouseNo: '',
            delLocalityName: '',
            delLandmark: '',
            delState: '',
            delStateId: 0,
            delDistrict: '',
            delDistrictId: 0,
            delCity: '',
            delCityId: 0,
            delPincode: '',
            // documents details
            photoUrl: '',
            aadharFrontUrl: '',
            aadharBackUrl: '',
            aadharNo: '',
            panNo: '',
            panUrl: '',
            gstNo: '',
            gstUrl: ''


        },
        validate: (data) => {
            let errors = {};
            if (!data.name) {
                errors.name = 'Name is required'
            }
            if (!data.mobile) {
                errors.mobile = 'Mobile no. is required';
            } else if (!/^\d+$/.test(data.mobile)) {
                errors.mobile = 'Mobile no. must be numeric';
            } else if (data.mobile.length !== 10) {
                errors.mobile = 'Mobile no. must be 10 digits';
            }
            if (!data.whatsappMob) {
                errors.whatsappMob = 'Whatsapp mobile no. is required';
            } else if (!/^\d+$/.test(data.whatsappMob)) {
                errors.whatsappMob = 'Whatsapp mobile no. must be numeric';
            } else if (data.whatsappMob.length !== 10) {
                errors.whatsappMob = 'Whatsapp mobile no. must be 10 digits';
            }
            if (!data.email) {
                errors.email = 'Email is required'
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                errors.email = 'Invalid email address.'
            }
            if (!data.officialEmail) {
                errors.officialEmail = 'Email is required'
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.officialEmail)) {
                errors.officialEmail = 'Invalid email address.'
            }
            if (!data.perHouseNo) {
                errors.perHouseNo = 'House / Flat / Block no. is required'
            }
            if (!data.perLocalityName) {
                errors.perLocalityName = 'Street / Colony / Locality name is required'
            }
            if (!data.perState) {
                errors.perState = 'State is required'
            }
            if (!data.perDistrict) {
                errors.perDistrict = 'District is required'
            }
            if (!data.perCity) {
                errors.perCity = 'City is required'
            }
            if (!data.perPincode) {
                errors.perPincode = 'Pin code no. is required';
            } else if (data.perPincode.length !== 6) {
                errors.perPincode = 'Pin code no. must be 6 digits';
            }
            if (!data.delHouseNo) {
                errors.delHouseNo = 'House / Flat / Block no. is required'
            }
            if (!data.delLocalityName) {
                errors.delLocalityName = 'Street / Colony / Locality name is required'
            }
            if (!data.delState) {
                errors.delState = 'State is required'
            }
            if (!data.delDistrict) {
                errors.delDistrict = 'District is required'
            }
            if (!data.delCity) {
                errors.delCity = 'City is required'
            }
            if (!data.delPincode) {
                errors.delPincode = 'Pin code no. is required';
            } else if (data.delPincode.length !== 6) {
                errors.delPincode = 'Pin code no. must be 6 digits';
            }
            console.log('errors', errors)
            return errors;
        },
        onSubmit: (data) => {
            console.log('registration data', data)
            // handleLogin(data);
        },
    });
    const isSearchFormFieldValid = (name) =>
        !!(searchFormik.touched[name] && searchFormik.errors[name]);
    const getSearchFormErrorMessage = (name) => {
        return (
            isSearchFormFieldValid(name) && (
                <small className="reg-error">{searchFormik.errors[name]}</small>
            )
        );
    };

    useEffect(() => {
        stateService()

    }, [])
    // --------------------- permanent address start----------------------

    const stateService = async () => {
        setLoading(true);

        await axios.get(`${Constants.baseUrl}master/geography/state?limit=1000`, {
            headers: {
                Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI4NjIiLCJpYXQiOjE3MDcxMDg5MjgsImV4cCI6MTcwNzI4ODkyOH0.G12I3H7WpSIhvmB7XnTi4e7Z5BPrrgquynX2Up_vRzg`, "Accept-Language": localStorage.getItem("langauge"),
                // Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
            }
        }).then((res) => {
            const dt = res.data;
            if (dt.result === 'success') {
                setLoading(false)
                setStateFetch(dt.output.results)
            } else if (dt === 'error' || dt.result === "dialog") {
                setLoading(false)
            }
        }).catch((err) => {
            setLoading(false);
        });
    }

    const handleStateChange = (e) => {
        const selectedId = e.target.value;

        // Clear delivery address fields only if checkedAddress is true
        // if (searchFormik.values.checkedAddress) {
        //     setDelDistrictFetch([]);
        //     setDelCityFetch([]);
        //     searchFormik.setValues({
        //         ...searchFormik.values,
        //         delStateId: selectedId,
        //         delDistrict: '',
        //         delDistrictId: 0,
        //         delCity: '',
        //         delCityId: 0,
        //         delPincode: '',
        //         perStateId: selectedId,
        //         perDistrict: '',
        //         perDistrictId: 0,
        //         perCity: '',
        //         perCityId: 0,
        //         perPincode: ''
        //     });
        // }


        // Clear permanent address fields when changing state
        setDistrictFetch([]);
        setCityFetch([]);
        searchFormik.setValues({
            ...searchFormik.values,
            perStateId: selectedId,
            perDistrict: '',
            perDistrictId: 0,
            perCity: '',
            perCityId: 0,
            perPincode: ''
        });

        // Clear all fields if selectedId is empty
        if (selectedId === '') {
            searchFormik.setValues({
                ...searchFormik.values,
                perState: '',
                perStateId: 0,
                perDistrict: '',
                perDistrictId: 0,
                perCity: '',
                perCityId: 0,
                perPincode: ''
            });
        } else {
            districtService(selectedId);
        }
    };


    const districtService = async (stateId) => {
        setLoading(true);

        await axios.get(`${Constants.baseUrl}master/geography/state/${stateId}`, {
            headers: {
                Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI4NjIiLCJpYXQiOjE3MDcxMDg5MjgsImV4cCI6MTcwNzI4ODkyOH0.G12I3H7WpSIhvmB7XnTi4e7Z5BPrrgquynX2Up_vRzg`, "Accept-Language": localStorage.getItem("langauge"),
                // Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
            }
        }).then((res) => {
            const dt = res.data;
            if (dt.result === 'success') {
                setDistrictFetch(dt.output.districts)
                setLoading(false)
                if (searchFormik.values.checkedAddress === true) {
                    searchFormik.setValues({
                        ...searchFormik.values,
                        perState: dt.output.name,
                        perStateId: dt.output.id,
                        delState: dt.output.name,
                        delStateId: dt.output.id

                    });
                } else {
                    searchFormik.setValues({
                        ...searchFormik.values,
                        perState: dt.output.name,
                        perStateId: dt.output.id,
                    });
                }

            } else if (dt === 'error' || dt.result === "dialog") {
                setLoading(false)
            }
        }).catch((err) => {
            setLoading(false);
        });
    }

    const handleDistrictChange = async (event) => {
        const selectedId = event.target.value
        setCityFetch([])
        searchFormik.setValues({
            ...searchFormik.values,
            perDistrictId: selectedId,
            perCity: '',
            perCityId: 0,
            perPincode: ''
        });
        if (selectedId === '') {
            setCityFetch([])
            searchFormik.setValues({
                ...searchFormik.values,
                perDistrict: '',
                perDistrictId: 0,
                perCity: '',
                perCityId: 0,
                perPincode: ''
            });

        } else {
            cityService(selectedId);
        }
    };

    const cityService = async (cityId) => {
        await axios.get(`${Constants.baseUrl}master/geography/district/${cityId}`, {
            headers: {
                Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI4NjIiLCJpYXQiOjE3MDcxMDg5MjgsImV4cCI6MTcwNzI4ODkyOH0.G12I3H7WpSIhvmB7XnTi4e7Z5BPrrgquynX2Up_vRzg`, "Accept-Language": localStorage.getItem("langauge"),
                // Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
            }
        }).then((res) => {
            const dt = res.data;
            if (dt.result === 'success') {
                setLoading(false)
                setCityFetch(dt.output.cities)
                if (searchFormik.values.checkedAddress === true) {
                    searchFormik.setValues({
                        ...searchFormik.values,
                        perDistrict: dt.output.name,
                        perDistrictId: dt.output.id,
                        delDistrict: dt.output.name,
                        delDistrictId: dt.output.id

                    });
                } else {
                    searchFormik.setValues({
                        ...searchFormik.values,
                        perDistrict: dt.output.name,
                        perDistrictId: dt.output.id
                    });
                }
            } else if (dt === 'error' || dt.result === "dialog") {
                setLoading(false)
            }
        }).catch((err) => {
            setLoading(false);
        });
    }

    const handleCityChange = (e) => {
        const selectedCityName = e.target.value;
        const city = cityFetch.find(city => city.name === selectedCityName);
        searchFormik.setValues({
            ...searchFormik.values,
            perCity: selectedCityName,
            perCityId: city?.id,
        });
    };
    // --------------------- permanent address end-----------------------

    // --------------------- delivery address start----------------------

    const handleDelStateChange = (e) => {
        const selectedId = e.target.value
        setDelDistrictFetch([])
        setDelCityFetch([])
        searchFormik.setValues({
            ...searchFormik.values,
            delStateId: selectedId,
            delDistrict: '',
            delDistrictId: 0,
            delCity: '',
            delCityId: 0,
            delPincode: ''
        });
        if (selectedId === '') {
            setDelDistrictFetch([])
            setDelCityFetch([])
            searchFormik.setValues({
                ...searchFormik.values,
                delState: '',
                delStateId: 0,
                delDistrict: '',
                delDistrictId: 0,
                delCity: '',
                delCityId: 0,
                delPincode: ''
            });
        } else {
            delDistrictService(selectedId)
        }
    }

    const delDistrictService = async (stateId) => {
        setLoading(true);

        await axios.get(`${Constants.baseUrl}master/geography/state/${stateId}`, {
            headers: {
                Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI4NjIiLCJpYXQiOjE3MDcxMDg5MjgsImV4cCI6MTcwNzI4ODkyOH0.G12I3H7WpSIhvmB7XnTi4e7Z5BPrrgquynX2Up_vRzg`, "Accept-Language": localStorage.getItem("langauge"),
                // Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
            }
        }).then((res) => {
            const dt = res.data;
            if (dt.result === 'success') {
                setLoading(false)
                setDelDistrictFetch(dt.output.districts)
                searchFormik.setValues({
                    ...searchFormik.values,
                    delState: dt.output.name
                });
            } else if (dt === 'error' || dt.result === "dialog") {
                setLoading(false)
            }
        }).catch((err) => {
            setLoading(false);
        });
    }

    const handleDelDistrictChange = (event) => {
        const selectedId = event.target.value
        setDelCityFetch([])
        searchFormik.setValues({
            ...searchFormik.values,
            delDistrictId: selectedId,
            delCity: '',
            delCityId: 0,
            delPincode: ''
        });
        if (selectedId === '') {
            setDelCityFetch([])
            searchFormik.setValues({
                ...searchFormik.values,
                delDistrict: '',
                delDistrictId: 0,
                delCity: '',
                delCityId: 0,
                delPincode: ''
            });

        } else {
            delCityService(selectedId);
        }
    };

    const delCityService = async (cityId) => {
        await axios.get(`${Constants.baseUrl}master/geography/district/${cityId}`, {
            headers: {
                Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI4NjIiLCJpYXQiOjE3MDcxMDg5MjgsImV4cCI6MTcwNzI4ODkyOH0.G12I3H7WpSIhvmB7XnTi4e7Z5BPrrgquynX2Up_vRzg`, "Accept-Language": localStorage.getItem("langauge"),
                // Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
            }
        }).then((res) => {
            const dt = res.data;
            if (dt.result === 'success') {
                setLoading(false)
                setDelCityFetch(dt.output.cities)
                searchFormik.setValues({
                    ...searchFormik.values,
                    delDistrict: dt.output.name
                });
            } else if (dt === 'error' || dt.result === "dialog") {
                setLoading(false)
            }
        }).catch((err) => {
            setLoading(false);
        });
    }

    const handleDelCityChange = (e) => {
        const selectedCityName = e.target.value;
        const city = delCityFetch.find(city => city.name === selectedCityName);
        console.log('del city', city)
        searchFormik.setValues({
            ...searchFormik.values,
            delCity: selectedCityName,
            delCityId: city.id
        });

    };
    // --------------------- delivery address end-----------------------



    const handleMobileChange = (e) => {
        const { values, setFieldValue } = searchFormik;

        const mobileValue = e.target.value;
        setFieldValue('mobile', mobileValue);
        if (values.checkedwhatsapp === true) {
            setFieldValue('whatsappMob', mobileValue)
        } else {
            setFieldValue('whatsappMob', '')
        }
    };

    const handleSameWhatsapp = () => {
        const { values, setFieldValue } = searchFormik;

        setFieldValue('checkedwhatsapp', !values.checkedwhatsapp);

        if (!values.checkedwhatsapp) {
            setFieldValue('whatsappMob', values.mobile);
        } else {
            setFieldValue('whatsappMob', '');
        }
    };

    const handleEmailChange = (e) => {
        const { values, setFieldValue } = searchFormik;

        const emailValue = e.target.value;

        searchFormik.setFieldValue('email', emailValue);

        if (values.checkedEmail === true) {
            setFieldValue('officialEmail', emailValue)
        } else {
            setFieldValue('officialEmail', '')
        }
    };


    const handleSameEmail = () => {
        const { values, setFieldValue, setFieldTouched } = searchFormik;

        // setFieldTouched('email');
        setFieldValue('checkedEmail', !values.checkedEmail);
        if (!values.checkedEmail) {
            setFieldValue('officialEmail', values.email);
        } else {
            setFieldValue('officialEmail', '');
        }
    };

    const handleSameAddress = (e) => {
        const { values, setValues } = searchFormik;

        const isChecked = e.target.checked;

        setValues(prevValues => ({
            ...prevValues,
            checkedAddress: isChecked,
            delHouseNo: isChecked ? values.perHouseNo : '',
            delLocalityName: isChecked ? values.perLocalityName : '',
            delLandmark: isChecked ? values.perLandmark : '',
            delState: isChecked ? values.perState : '',
            delStateId: isChecked ? values.perStateId : 0,
            delDistrict: isChecked ? values.perDistrict : '',
            delDistrictId: isChecked ? values.perDistrictId : 0,
            delCity: isChecked ? values.perCity : '',
            delCityId: isChecked ? values.perCityId : 0,
            delPincode: isChecked ? values.perPincode : ''
        }));
    };


    const handleHouseChange = (e) => {
        const { values, setFieldValue } = searchFormik;
        const value = e.target.value
        setFieldValue('perHouseNo', value);
        if (values.checkedAddress === true) {
            setFieldValue('delHouseNo', value)
        } else {
            setFieldValue('delHouseNo', '')
        }
    };
    const handleLocalityChange = (e) => {
        const { values, setFieldValue } = searchFormik;
        const value = e.target.value
        setFieldValue('perLocalityName', value);
        if (values.checkedAddress === true) {
            setFieldValue('delLocalityName', value)
        } else {
            setFieldValue('delLocalityName', '')
        }
    };
    const handleLandmarkChange = (e) => {
        const { values, setFieldValue } = searchFormik;
        const value = e.target.value
        setFieldValue('perLandmark', value);
        if (values.checkedAddress === true) {
            setFieldValue('delLandmark', value)
        } else {
            setFieldValue('delLandmark', '')
        }
    };
    const handlePincodeChange = (e) => {
        const { values, setFieldValue } = searchFormik;
        const value = e.target.value
        setFieldValue('perPincode', value);
        if (values.checkedAddress === true) {
            setFieldValue('delPincode', value)
        } else {
            setFieldValue('delPincode', '')
        }
    };


    // ---------------------upload douments api's start----------------------------
    const handlePhoto = (event) => {
        updatePhoto(event.target.files[0]);
    };
    const handleAadharFront = (event) => {
        updateAdhaarFront(event.target.files[0]);
    };
    const handleAadharBack = (event) => {
        updateAdhaarBack(event.target.files[0]);
    };
    const handlePanCard = (event) => {
        updatePan(event.target.files[0]);
    };
    const handleGst = (event) => {
        updateGst(event.target.files[0]);
    }



    const updatePhoto = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', 'photo');
        await axios.post(Constants.baseUrl + 'uploader/upload', formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept-Language': localStorage.getItem('language'),
                platform: Constants.pt,
                appVersion: Constants.av,
                company: Constants.company,
                program: Constants.program,
            },
        }).then((res) => {
            const dt = res.data;
            if (dt.result === 'success') {
                setLoading(false)
                searchFormik.setFieldValue('photoUrl', dt.output.url)
            } else if (dt === 'error' || dt.result === "dialog") {
                setLoading(false)
            }
        }).catch((err) => {
            setLoading(false);
        });
    }

    const updateAdhaarFront = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', 'aadhar');
        await axios.post(Constants.baseUrl + 'uploader/upload', formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept-Language': localStorage.getItem('language'),
                platform: Constants.pt,
                appVersion: Constants.av,
                company: Constants.company,
                program: Constants.program,
            },
        }).then((res) => {
            const dt = res.data;
            if (dt.result === 'success') {
                setLoading(false)
                searchFormik.setFieldValue('aadharFrontUrl', dt.output.url)
            } else if (dt === 'error' || dt.result === "dialog") {
                setLoading(false)
            }
        }).catch((err) => {
            setLoading(false);
        });
    }
    const updateAdhaarBack = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', 'aadhar');

        await axios.post(Constants.baseUrl + 'uploader/upload', formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept-Language': localStorage.getItem('language'),
                platform: Constants.pt,
                appVersion: Constants.av,
                company: Constants.company,
                program: Constants.program,
            },
        }).then((res) => {
            const dt = res.data;
            if (dt.result === 'success') {
                setLoading(false)
                searchFormik.setFieldValue('aadharBackUrl', dt.output.url)
            } else if (dt === 'error' || dt.result === "dialog") {
                setLoading(false)
            }
        }).catch((err) => {
            setLoading(false);
        });
    }
    const updatePan = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', 'pan');

        await axios.post(Constants.baseUrl + 'uploader/upload', formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept-Language': localStorage.getItem('language'),
                platform: Constants.pt,
                appVersion: Constants.av,
                company: Constants.company,
                program: Constants.program,
            },
        }).then((res) => {
            const dt = res.data;
            if (dt.result === 'success') {
                setLoading(false)
                searchFormik.setFieldValue('panUrl', dt.output.url)
            } else if (dt === 'error' || dt.result === "dialog") {
                setLoading(false)
            }
        }).catch((err) => {
            setLoading(false);
        });
    }
    const updateGst = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', 'cheque');

        await axios.post(Constants.baseUrl + 'uploader/upload', formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept-Language': localStorage.getItem('language'),
                platform: Constants.pt,
                appVersion: Constants.av,
                company: Constants.company,
                program: Constants.program,
            },
        }).then((res) => {
            const dt = res.data;
            if (dt.result === 'success') {
                setLoading(false)
                searchFormik.setFieldValue('gstUrl', dt.output.url)
            } else if (dt === 'error' || dt.result === "dialog") {
                setLoading(false)
            }
        }).catch((err) => {
            setLoading(false);
        });
    }

    // ---------------------upload douments api's end ----------------------------


    return (
        <>
            <Header />
            <section className="blog-area pt182">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="heading-banner-title">
                                            <h4 className="text-center mb25">Registration Form</h4>
                                        </div>
                                    </div>
                                </div>
                                <form onSubmit={searchFormik.handleSubmit}>
                                    <div className="row clearfix">
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label> Name <sup>*</sup></label>
                                                <input className="form-control" placeholder="Name*" type="text" id='name' value={searchFormik.values.name} onChange={searchFormik.handleChange} />
                                                {getSearchFormErrorMessage('name')}
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Gender <sup>*</sup></label>
                                                <div className="h34">
                                                    <label className="radio-inline">
                                                        <input type="radio" name="marital_status" value="male" checked={selectedOption === 'male'} onChange={(e) => setSelectedOption(e.target.value)} /> Male
                                                    </label>
                                                    <label className="radio-inline">
                                                        <input type="radio" name="marital_status" value="female" checked={selectedOption === 'female'} onChange={(e) => setSelectedOption(e.target.value)} /> Female
                                                    </label>
                                                    <label className="radio-inline">
                                                        <input type="radio" name="marital_status" value="others" checked={selectedOption === 'others'} onChange={(e) => setSelectedOption(e.target.value)} /> Others
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>D.O.B <sup>*</sup></label><br />
                                                <DatePicker
                                                    className="form-control datepickerdob"
                                                    selected={searchFormik.values.dob}
                                                    onChange={(date) => searchFormik.setFieldValue('dob', date)}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    autoComplete="off"
                                                    scrollableYearDropdown
                                                    maxDate={new Date()}
                                                    onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                                                    onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                                                    onClick={(e) => e.preventDefault()} // Preven
                                                    placeholderText='DD/MM/YYYY *'
                                                />
                                                {getSearchFormErrorMessage('dob')}
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Mobile No. <sup>*</sup></label>
                                                <input type="text" id='mobile' value={searchFormik.values.mobile} maxLength="10" onChange={handleMobileChange}
                                                    className="form-control" placeholder="Mobile No." onKeyPress={(e) => {
                                                        const isNumeric = /^[0-9]*$/;
                                                        if (!isNumeric.test(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                    }} />
                                                {getSearchFormErrorMessage('mobile')}
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label className="checkbox-inline mb-5 cllrs">
                                                    <input className="form-control" id="checkedwhatsapp" type="checkbox" checked={searchFormik.values.checkedwhatsapp} onChange={handleSameWhatsapp} />
                                                    If Whatsapp No is same as Mobile No. <sup>*</sup>
                                                </label>
                                                <input type="text" id='whatsappMob' value={searchFormik.values.whatsappMob} maxLength="10" onChange={searchFormik.handleChange}
                                                    className="form-control" placeholder="Whatsapp Mobile No." onKeyPress={(e) => {
                                                        const isNumeric = /^[0-9]*$/;
                                                        if (!isNumeric.test(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                    }} disabled={searchFormik.values.checkedwhatsapp}
                                                />
                                                {getSearchFormErrorMessage('whatsappMob')}
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>E-Mail ID Personal  <sup>*</sup></label>
                                                <input className="form-control" type="text" id="email" placeholder="E-Mail ID Personal *" value={searchFormik.values.email} onChange={handleEmailChange} />
                                                {getSearchFormErrorMessage('email')}
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label className="checkbox-inline mb-5 cllrs">
                                                    <input className="form-control" id="checkedEmail" type="checkbox" checked={searchFormik.values.checkedEmail} onChange={handleSameEmail} />
                                                    If Official E-Mail is same as Personal<sup>*</sup>
                                                </label>
                                                <input className="form-control" type="text" placeholder="E-Mail ID Official *"
                                                    id="officialEmail" value={searchFormik.values.officialEmail}
                                                    onChange={searchFormik.handleChange}
                                                    disabled={searchFormik.values.checkedEmail} />
                                                {getSearchFormErrorMessage('officialEmail')}

                                            </div>
                                        </div>

                                        {/* <div className="col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group has-float-label style-two">
                                            <label>Operational Region  <sup>*</sup></label>
                                            <select className="form-control2">
                                                <option value="" selected="">	Select Region </option>
                                                <option value="">	East </option>
                                                <option value="">	West</option>
                                                <option value="">	North </option>
                                                <option value="">	South </option>
                                            </select>
                                        </div>
                                     </div> */}

                                        <div className="col-md-3 col-sm-12 col-xs-12 hidden">
                                            <div className="form-group has-float-label style-two">
                                                <label>Marital Status  <sup>*</sup></label>
                                                <div className="h34">
                                                    <label className="radio-inline">
                                                        <input type="radio" name="marital_status" value="single" /> Single
                                                    </label>
                                                    <label className="radio-inline">
                                                        <input type="radio" name="marital_status" value="married" /> Married
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-xs-12">
                                            <h4 className="midhead">Permanent Address  </h4>
                                        </div>

                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>House / Flat / Block No.  <sup>*</sup></label>
                                                <input className="form-control" placeholder="House / Flat / Block No. *" id="perHouseNo" type="text" value={searchFormik.values.perHouseNo} onChange={handleHouseChange} />
                                                {getSearchFormErrorMessage('perHouseNo')}
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Street / Colony / Locality Name <sup>*</sup></label>
                                                <input className="form-control" placeholder="Street / Colony / Locality Name *" id="perLocalityName" type="text" value={searchFormik.values.perLocalityName} onChange={handleLocalityChange} />
                                                {getSearchFormErrorMessage('perLocalityName')}
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Landmark  </label>
                                                <input className="form-control" placeholder="Landmark" type="text" id="perLandmark" value={searchFormik.values.perLandmark} onChange={handleLandmarkChange} />
                                                {getSearchFormErrorMessage('perLandmark')}
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>State  <sup>*</sup></label>
                                                <select className="form-control2" id="perState" onChange={handleStateChange}>
                                                    <option value=''>Select State </option>
                                                    {stateFetch.map((item, index) => {
                                                        return (
                                                            <>
                                                                <option key={index} value={item.id}>{item.name}</option>
                                                            </>
                                                        )
                                                    })}
                                                </select>
                                                {getSearchFormErrorMessage('perState')}
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>District  <sup>*</sup></label>
                                                <select className="form-control2" id='perDistrict' onChange={(e) => handleDistrictChange(e)}>
                                                    <option value='' >Select District </option>
                                                    {districtFetch.map((item, index) => {
                                                        return (
                                                            <>
                                                                <option key={index} value={item.id}>{item.name}</option>
                                                            </>
                                                        )
                                                    })}
                                                </select>
                                                {getSearchFormErrorMessage('perDistrict')}
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>City <sup>*</sup></label>
                                                <select className="form-control2" id='perCity' onChange={(e) => handleCityChange(e)}>
                                                    <option value=''>Select City</option>
                                                    {cityFetch.map((item, index) => {
                                                        return (
                                                            <>
                                                                <option key={index} value={item.name}>{item.name}</option>
                                                            </>
                                                        )
                                                    })}
                                                </select>
                                                {getSearchFormErrorMessage('perCity')}
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Pin Code <sup>*</sup></label>
                                                <input className="form-control" placeholder="Pin Code *"
                                                    maxLength='6' id='perPincode'
                                                    type="text"
                                                    value={searchFormik.values.perPincode}
                                                    onChange={handlePincodeChange}
                                                    onKeyPress={(e) => {
                                                        const isNumeric = /^[0-9]*$/;
                                                        if (!isNumeric.test(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                    }} />
                                                {getSearchFormErrorMessage('perPincode')}
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-xs-12">
                                            <h4 className="midhead">Delivery Address<span>*</span>  </h4>
                                            <label className="checkbox-inline mb-10 cllrs">
                                                <input className="form-control" id="checkedAddress" type="checkbox" checked={searchFormik.values.checkedAddress} onChange={handleSameAddress} /> If Delivery address is same as Permanent address</label>
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>House / Flat / Block No.  <sup>*</sup></label>
                                                <input className="form-control" placeholder="House / Flat / Block No. *" type="text" id="delHouseNo"
                                                    value={searchFormik.values.delHouseNo}
                                                    onChange={searchFormik.handleChange}
                                                    disabled={searchFormik.values.checkedAddress}
                                                />
                                                {getSearchFormErrorMessage('delHouseNo')}
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Street / Colony / Locality Name <sup>*</sup></label>
                                                <input className="form-control" placeholder="Street / Colony / Locality Name *" type="text" id="delLocalityName" value={searchFormik.values.delLocalityName} onChange={searchFormik.handleChange} disabled={searchFormik.values.checkedAddress} />
                                                {getSearchFormErrorMessage('delLocalityName')}
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Landmark  </label>
                                                <input className="form-control" placeholder="Landmark  *" type="text" id="delLandmark" value={searchFormik.values.delLandmark} onChange={searchFormik.handleChange} disabled={searchFormik.values.checkedAddress} />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>State  <sup>*</sup></label>
                                                <select className="form-control2" id="delState" onChange={searchFormik.values.checkedAddress ? handleStateChange : handleDelStateChange} disabled={searchFormik.values.checkedAddress}>
                                                    {searchFormik.values.checkedAddress === true ? (
                                                        <option value={searchFormik.values.perState} >{searchFormik.values.perState}</option>
                                                    ) : (
                                                        <>
                                                            <option value=''>Select State </option>
                                                            {stateFetch.map((item, index) => (
                                                                <option key={index} value={item.id}>{item.name}</option>
                                                            ))}
                                                        </>
                                                    )}
                                                </select>
                                                {getSearchFormErrorMessage('delState')}
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>District  <sup>*</sup></label>
                                                <select className="form-control2" id='delDistrict' onChange={searchFormik.values.checkedAddress ? handleDistrictChange : handleDelDistrictChange} disabled={searchFormik.values.checkedAddress}>
                                                    {searchFormik.values.checkedAddress === true ? (
                                                        <option value={searchFormik.values.perDistrict} >{searchFormik.values.perDistrict}</option>
                                                    ) : (
                                                        <>
                                                            <option value='' >Select District </option>
                                                            {delDistrictFetch.map((item, index) => (
                                                                <option key={index} value={item.id}>{item.name}</option>
                                                            ))}
                                                        </>
                                                    )}
                                                </select>
                                                {getSearchFormErrorMessage('delDistrict')}
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>City <sup>*</sup></label>
                                                <select
                                                    className="form-control2"
                                                    id='delCity'
                                                    onChange={searchFormik.values.checkedAddress ? handleCityChange : handleDelCityChange}
                                                    disabled={searchFormik.values.checkedAddress}
                                                >
                                                    {searchFormik.values.checkedAddress === true ? (
                                                        <option value={searchFormik.values.perCity}>{searchFormik.values.perCity}</option>
                                                    ) : (
                                                        <>
                                                            <option value=''>Select City</option>
                                                            {delCityFetch && delCityFetch.length > 0 && delCityFetch.map((item, index) => (
                                                                <option key={index} value={item.name}>{item.name}</option>
                                                            ))}
                                                        </>
                                                    )}
                                                </select>
                                                {getSearchFormErrorMessage('delCity')}
                                            </div>

                                        </div>

                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Pin Code <sup>*</sup></label>
                                                <input className="form-control" placeholder="Pin Code *"
                                                    maxLength='6' id='delPincode'
                                                    type="text"
                                                    value={searchFormik.values.delPincode}
                                                    onChange={searchFormik.handleChange}
                                                    onKeyPress={(e) => {
                                                        const isNumeric = /^[0-9]*$/;
                                                        if (!isNumeric.test(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                    }} disabled={searchFormik.values.checkedAddress} />
                                                {getSearchFormErrorMessage('delPincode')}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row clearfix mt-15">
                                        <div className="col-md-12 col-xs-12">
                                            <h4 className="midhead">Upload ID Proof</h4>
                                        </div>

                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Upload Your Photo <sup>*</sup></label>
                                                {searchFormik.values.photoUrl !== '' ? <Link to={searchFormik.values.photoUrl} target="_blank" className='view-image'><span><i className="fa fa-eye">View</i></span></Link> : ''}
                                                <input className="form-control" type="file" accept=".jpg, .jpeg, .png, .svg" onChange={handlePhoto} />

                                            </div>
                                        </div>

                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Upload Aadhar Card <sup>*</sup></label>
                                                {searchFormik.values.aadharFrontUrl !== '' ? <Link to={searchFormik.values.aadharFrontUrl} target="_blank" className='view-image'><span><i className="fa fa-eye">View</i></span></Link> : ''}
                                                <input className="form-control" type="file" accept=".jpg, .jpeg, .png, .svg" onChange={handleAadharFront} />
                                                <span className="ff">Front Copy</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Upload Aadhar Card <sup>*</sup></label>
                                                {searchFormik.values.aadharBackUrl !== '' ? <Link to={searchFormik.values.aadharBackUrl} target="_blank" className='view-image'><span><i className="fa fa-eye">View</i></span></Link> : ''}
                                                <input className="form-control" type="file" accept=".jpg, .jpeg, .png, .svg" onChange={handleAadharBack} />
                                                <span className="ff">Back Copy</span>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Aadhar  No. <sup>*</sup></label>
                                                <input className="form-control" placeholder="Aadhar No." type="text" />

                                            </div>
                                        </div>

                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label> PAN No. </label>
                                                <input className="form-control" id="txtPan" placeholder="PAN No." type="text" />

                                            </div>
                                        </div>

                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Upload PAN Card </label>
                                                {searchFormik.values.panUrl !== '' ? <Link to={searchFormik.values.panUrl} target="_blank" className='view-image'><span><i className="fa fa-eye">View</i></span></Link> : ''}
                                                <input className="form-control" id="txtPan_photo" type="file" accept=".jpg, .jpeg, .png, .svg" onChange={handlePanCard} />
                                            </div>
                                        </div>


                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label> GST No.  </label>
                                                <input className="form-control" id="txtGst" placeholder="GST Number" type="text" />

                                            </div>
                                        </div>

                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Upload GST Photo </label>
                                                {searchFormik.values.gstUrl !== '' ? <Link to={searchFormik.values.gstUrl} target="_blank" className='view-image'><span><i className="fa fa-eye">View</i></span></Link> : ''}
                                                <input className="form-control" id="txtGst_photo" type="file" accept=".jpg, .jpeg, .png, .svg" onChange={handleGst} />
                                            </div>
                                        </div>

                                        <div className="col-md-12 col-xs-12">
                                            <h4 className="midhead">Brands Consumption Details</h4>
                                        </div>

                                        <div className="col-md-5 col-sm-5 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Brands Consumption</label>
                                                <div className="type_and_value">
                                                    <label className="checkbox-inline"><input type="checkbox" value="" /> Folio
                                                        <input type="text" className="form-control" placeholder="Consumption" /></label>
                                                    <label className="checkbox-inline"><input type="checkbox" value="" /> Carton
                                                        <input type="text" className="form-control" placeholder="Consumption" /></label>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <div className="form-group has-float-label style-two">
                                                <label>Brands Consumed  </label>
                                                <input className="form-control" placeholder="Brands Consumed" type="text" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12 col-xs-12 mt-15 mb-15">
                                        <div className="text-center">
                                            <button type="submit" name="button" className="btn login_btn">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
