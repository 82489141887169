import React from 'react'
import first from '../../../assets/img/1ststep.png'
import second from '../../../assets/img/2ndstep.png'
import third from '../../../assets/img/3rdstep.png'
export default function ProgramStages() {
    return (
        <>
            <section class="blog-area  about-hafelep">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="all-single-blog">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="heading-banner-title">
                                            <h4><span>Program Stages</span></h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="row clearfix stepsprgm">
                                    <div class="col-md-4 col-xs-12 programstage">
                                        <figure>
                                            <label>STEP 1</label>
                                            <img src={first} alt="" />
                                            <figcaption>Register by <br /> Scanning QR Code</figcaption>
                                        </figure>
                                    </div>

                                    <div class="col-md-4 col-xs-12 programstage">
                                        <figure>
                                            <label>STEP 2</label>
                                            <img src={second} alt="" />
                                            <figcaption>Upload the <br /> Purchase</figcaption>
                                        </figure>
                                    </div>
                                    <div class="col-md-4 col-xs-12 programstage">
                                        <figure>
                                            <label>STEP 3</label>
                                            <img src={third} alt="" />
                                            <figcaption>Earn Monthly Exciting <br /> Rewards and Benefit by <br />scaning the QR Code inside</figcaption>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
