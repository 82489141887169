import React, { useEffect } from 'react'
import Header from '../../common/Header'
import Login from '../Auth/Login'
import Footer from '../../common/Footer'
import { useNavigate } from 'react-router-dom'
export default function Home() {
    const navigate = useNavigate()
    useEffect(() => {
        const userType = localStorage.getItem('user_type');
        if (userType === 'RO' || userType === 'HO') {
            navigate('/dashboard')
        }

    }, [])


    return (
        <>
            <Header />
            <Login />
            <Footer />
        </>
    )
}
