import React, { useState, useEffect } from 'react'
import bond_forever from '../../../assets/img/bond_forever.png'
import logo2 from '../../../assets/img/logo/logo2.png'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import '../../../assets/css/style.css'
import axios from 'axios'
import * as Constants from '../../../Constants/index'
import { useNavigate } from 'react-router-dom'
import swal from 'sweetalert';
export default function Login() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [selectedOption, setSelectedOption] = useState('password');
    const [otpSent, setOtpSent] = useState(false)
    //login with password
    const loginFormik = useFormik({
        initialValues: {
            mobile: "",
            password: "",
        },
        validate: (data) => {
            let errors = {};
            if (!data.mobile) {
                errors.mobile = 'Mobile no. is required';
            } else if (!/^\d+$/.test(data.mobile)) {
                errors.mobile = 'Mobile no. must be numeric';
            } else if (data.mobile.length !== 10) {
                errors.mobile = 'Mobile no. must be 10 digits';
            }
            if (!data.password) {
                errors.password = 'Password is required';
            }

            return errors;
        },
        onSubmit: (data) => {
            // console.log('login data', data)
            handleLogin(data);
        },
    });
    const isLoginFormFieldValid = (name) =>
        !!(loginFormik.touched[name] && loginFormik.errors[name]);
    const getLoginFormErrorMessage = (name) => {
        return (
            isLoginFormFieldValid(name) && (
                <small className="p-error">{loginFormik.errors[name]}</small>
            )
        );
    };

    //login with otp
    const loginOtpFormik = useFormik({
        initialValues: {
            regMobile: "",
            otp: "",
        },
        validate: (data) => {
            let errors = {};
            if (!data.regMobile) {
                errors.regMobile = 'Registered mobile no. is required';
            } else if (!/^\d+$/.test(data.regMobile)) {
                errors.regMobile = 'Registered mobile no. must be numeric';
            } else if (data.regMobile.length !== 10) {
                errors.regMobile = 'Registered mobile no. must be 10 digits';
            }
            if (otpSent === true) {
                if (!data.otp) {
                    errors.otp = 'OTP is required';
                } else if (!/^\d+$/.test(data.otp)) {
                    errors.otp = 'OTP must be numeric';
                } else if (data.otp.length !== 6) {
                    errors.otp = 'OTP must be 6 digits';
                }
            }

            console.log(errors)
            return errors;
        },
        onSubmit: (data) => {
            // console.log('login data', data)
            if (otpSent === false) {
                handleLoginOtp(data);
            } else {
                handleVerifyOtp(data);
            }
        },
    });
    const isLoginOtpFormFieldValid = (name) =>
        !!(loginOtpFormik.touched[name] && loginOtpFormik.errors[name]);
    const getLoginOtpFormErrorMessage = (name) => {
        return (
            isLoginOtpFormFieldValid(name) && (
                <small className="p-error">{loginOtpFormik.errors[name]}</small>
            )
        );
    };

    const handleLogin = async (data) => {
        setLoading(true);
        const postData = {
            mobile: data.mobile,
            password: data.password,
        }
        await axios.post(Constants.baseUrl + 'auth/login-password', postData ,
            {
                headers: {
                    'Accept-Language': localStorage.getItem('language'),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program,
                },
            }).then((res) => {
                const dt = res.data;
                if (dt.result === 'success') {
                    const ndt = dt.output
                    setLoading(false)
                    if (ndt?.user?.userType === 'RO' || ndt?.user?.userType === 'HO') {
                        navigate('/dashboard')
                        localStorage.setItem('user_id', dt.output?.user?.id);
                        localStorage.setItem('user_type', dt.output?.user?.userType);
                        localStorage.setItem('user_name', dt.output?.user?.name);
                        localStorage.setItem('user_email', dt.output?.user?.email);
                        localStorage.setItem('user_mobile', dt.output?.user?.mobile);
                        localStorage.setItem('token', dt.output?.access?.token);
                        localStorage.setItem('userUniq_id', dt.output?.user?.userUniqid);
                    }
                } else if (dt.result === 'error' || dt.result === "dialog") {
                    setLoading(false)
                    swal({
                        text: dt.msg,
                        icon: 'error',
                        buttons: false,
                        timer: 2000
                    });
                }
            }).catch((err) => {
                setLoading(false);
            });
    }

    const handleLoginOtp = async (data) => {
        setLoading(true);
        await axios.post(Constants.baseUrl + 'auth/login-otp',
            {
                mobile: data.regMobile,
                password: '',
            },
            {
                headers: {
                    'Accept-Language': localStorage.getItem('language'),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program,
                },
            }).then((res) => {
                const dt = res.data;
                if (dt.result === 'success') {
                    setOtpSent(true)
                    setLoading(false)
                    swal({
                        text: dt.msg,
                        icon: 'success',
                        buttons: false,
                        timer: 2000
                    });
                } else if (dt.result === 'error' || dt.result === "dialog") {
                    setLoading(false)
                }
            }).catch((err) => {
                setLoading(false);
            });
    }

    const handleVerifyOtp = async (data) => {
        setLoading(true);
        await axios.post(Constants.baseUrl + 'auth/verify-otp',
            {
                mobile: data.regMobile,
                otp: data.otp,
            },
            {
                headers: {
                    'Accept-Language': localStorage.getItem('language'),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program,
                },
            }).then((res) => {
                const dt = res.data;
                if (dt.result === 'success') {
                    const ndt = dt.output
                    setLoading(false)
                    if (ndt?.user?.userType === 'RO' || ndt?.user?.userType === 'HO') {
                        localStorage.setItem('user_id', dt.output.user.id);
                        localStorage.setItem('user_type', dt.output.user.userType);
                        localStorage.setItem('user_name', dt.output.user.name);
                        localStorage.setItem('user_email', dt.output.user.email);
                        localStorage.setItem('user_mobile', dt.output.user.mobile);
                        localStorage.setItem('token', dt.output.access.token);
                        localStorage.setItem('userUniq_id', dt.output.user.userUniqid);
                        navigate('/dashboard')
                    } else {

                    }
                } else if (dt.result === 'error' || dt.result === "dialog") {
                    setLoading(false)
                    swal({
                        text: dt.msg,
                        icon: 'error',
                        buttons: false,
                        timer: 2000
                    });
                }
            }).catch((err) => {
                setLoading(false);
            });
    }

    const handleKeyPassword = (event) => {
        if (event.key === 'Enter') {
            loginFormik.handleSubmit();
        }
    };
    const handleKeyOtp = (event) => {
        if (event.key === 'Enter') {
            loginOtpFormik.handleSubmit();
        }
    };
    return (
        <>
            <div className="hero_cover_one mt128" id="myDiv">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-sm-8 col-xs-12">
                            <div className="innerdiv row">
                                <div className="col-md-7 nopaddleft col-sm-7 col-xs-12 hidden-xs">
                                    <figure className="combo">
                                        <img src={bond_forever} className="img-responsive" alt="get color combo pack" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="mobile-bond-logo mtoplog">
                                <img src={logo2} alt="" />
                            </div>
                            <div className="enquiryForm">
                                <h1 className="contacttag">LOGIN HERE</h1>
                                <div className="h34ottp">
                                    <label className="radio-inline">
                                        <input type="radio" value='password' checked={selectedOption === 'password'} onChange={(e) => {
                                            setSelectedOption(e.target.value)
                                            setOtpSent(false)
                                            loginOtpFormik.setFieldValue('regMobile', '')
                                            loginOtpFormik.setFieldValue('otp', '')
                                        }} /> Password
                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" value='otp' checked={selectedOption === 'otp'} onChange={(e) => {
                                            setSelectedOption(e.target.value)
                                            loginFormik.setFieldValue('mobile', '')
                                            loginFormik.setFieldValue('password', '')
                                        }} /> OTP
                                    </label>
                                </div>
                                {selectedOption === 'password' ?
                                    <form onSubmit={loginFormik.handleSubmit}>
                                        <div className="regwindow">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <span className="input-group-addon">
                                                        <span className="fa fa-user" ></span>
                                                    </span>
                                                    <input type="text" id='mobile' value={loginFormik.values.mobile} maxLength="10" onChange={loginFormik.handleChange}
                                                        className="form-control frm" placeholder="User ID" onKeyDown={handleKeyPassword} onKeyPress={(e) => {
                                                            const isNumeric = /^[0-9]*$/;
                                                            if (!isNumeric.test(e.key)) {
                                                                e.preventDefault();
                                                            }
                                                        }} />
                                                    {getLoginFormErrorMessage('mobile')}
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <span className="input-group-addon">
                                                        <span className="fa fa-key" aria-hidden="true"></span>
                                                    </span>
                                                    <input type='password' id="password" value={loginFormik.values.password} onChange={loginFormik.handleChange}
                                                        className="form-control frm" placeholder="Password" onKeyDown={handleKeyPassword} />
                                                    {getLoginFormErrorMessage('password')}
                                                </div>
                                            </div>
                                            <div className="callback">
                                                <button className="btn btn-primary landingsubmit" type="submit"><i className="fa fa-sign-in"></i> Login</button>
                                            </div>
                                        </div>
                                    </form> : ''}
                                {selectedOption === 'otp' ?
                                    <div className="regwindow">
                                        {/* <!-- <h1 className="contacttag">OTP Login</h1> --> */}
                                        <form onSubmit={loginOtpFormik.handleSubmit}>
                                            <div>
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon">
                                                            <span className="fa fa-mobile" ></span>
                                                        </span>
                                                        <input type="text" id='regMobile' value={loginOtpFormik.values.regMobile} maxLength="10" onChange={loginOtpFormik.handleChange}
                                                            className="form-control frm" placeholder="Registered Mobile No." onKeyDown={handleKeyOtp} onKeyPress={(e) => {
                                                                const isNumeric = /^[0-9]*$/;
                                                                if (!isNumeric.test(e.key)) {
                                                                    e.preventDefault();
                                                                }
                                                            }} disabled={otpSent} />
                                                        {getLoginOtpFormErrorMessage('regMobile')}
                                                    </div>
                                                </div>
                                                {otpSent === true ?
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <span className="input-group-addon">
                                                                <span className="fa fa-key"></span>
                                                            </span>
                                                            <input type="text" placeholder="Enter OTP" id='otp' className="form-control frm" maxLength='6'
                                                                value={loginOtpFormik.values.otp} onChange={loginOtpFormik.handleChange}
                                                                onKeyPress={(e) => {
                                                                    const isNumeric = /^[0-9]*$/;
                                                                    if (!isNumeric.test(e.key)) {
                                                                        e.preventDefault();
                                                                    }
                                                                }} />
                                                            {getLoginOtpFormErrorMessage('otp')}
                                                        </div>
                                                    </div> : ''}
                                                <div className="callback divotp">
                                                    <button type="submit" name="button" className="btn btn-primary landingsubmit">{otpSent === false ? 'Generate  OTP' : 'Verify OTP'}</button>
                                                </div>
                                            </div>
                                            {/* <div>
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon">
                                                            <span className="fa fa-key"></span>
                                                        </span>
                                                        <input type="text" placeholder="Enter OTP" className="form-control frm" />
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-center mt-3 login_container">
                                                    <button type="button" name="button" className="btn btn-primary landingsubmit">Verify OTP</button>
                                                </div>
                                            </div> */}
                                        </form>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
