import React from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import login_icon from "../../../assets/img/login_icon.png";
import enroll from "../../../assets/img/enroll.png";
import redemption from "../../../assets/img/redemption.png";
import qr_transac from "../../../assets/img/qr-transac.png";
import { Link } from "react-router-dom";

export default function Dashboard() {
  return (
    <>
      <Header />
      <section className="blog-area pt182 min477">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              {/* <!-- <div className="col-md-8 col-sm-8 col-md-offset-2 col-sm-offset-2 col-xs-12"> --> */}
              <div className="all-single-blog">
                <div className="row mt-15">
                  <div className="col-md-3">
                    <div className="info-box_1 nflink wd80">
                      <Link to="/enrollment_report">
                        <div className="info-box-content">
                          <img src={enroll} alt="" />
                          <span className="info-box-text">
                            Enrollment Report{" "}
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="info-box_1 nflink wd80">
                      <Link to="/QR_transaction_report">
                        <div className="info-box-content">
                          <img src={qr_transac} alt="" />
                          <span className="info-box-text">
                            QR Transaction Report
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="info-box_1 nflink wd80">
                      <Link to="/redemption_report">
                        <div className="info-box-content">
                          <img src={redemption} alt="" />
                          <span className="info-box-text">
                            Redemption Report
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="info-box_1 nflink wd80">
                      <Link to="/points_report">
                        <div className="info-box-content">
                          <img src={login_icon} alt="" />
                          <span className="info-box-text">Points Report</span>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
