import React, { useEffect, useState } from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { Link, useNavigate } from "react-router-dom";
import ResponsivePagination from "react-responsive-pagination";
import Oval from "../Loader/CircleLoade";
import * as Constants from "../../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import moment from "moment";
import { saveAs } from "file-saver";
import { useFormik } from "formik";

export default function Redemption() {
  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(10);
  const [filterData, setFilterData] = useState({});
  const [downloadLoading, setDownloadLoading] = useState(false);

  useEffect(() => {
    getReportData(
      {
        mobile: "",
        status: "",
        startDate: "",
        endDate: "",
      },
      1
    );
  }, []);

  const searchFormik = useFormik({
    initialValues: {
      mobile: "",
      status: "",
      startDate: "",
      endDate: "",
    },
    validate: (data) => {
      let errors = {};
			if (data.mobile) {
        if (data.mobile.length < 10) {
          errors.mobile = "Mobile must be in 10 digits.";
        }
      }
      if (data.startDate === "" && data.endDate !== "") {
        errors.startDate = "Please select date";
      }
      if (data.startDate !== "" && data.endDate === "") {
        errors.endDate = "Please select date";
      }
      if (data.startDate && data.endDate && data.startDate > data.endDate) {
        errors.endDate = "Please select date";
      }
      if (data.startDate && data.endDate && data.startDate > data.endDate) {
        errors.endDate = "End date should be greater than start date";
      }
      // if (data.startDate && data.endDate) {
      //   const maxEndDate = new Date(data.startDate);
      //   maxEndDate.setDate(maxEndDate.getDate() + 30); // Adjust the range as needed (e.g., 31 days)

      //   if (data.endDate > maxEndDate) {
      //     errors.endDate =
      //       "End date should be within 31 days from the start date.";
      //   }
      // }
      return errors;
    },
    onSubmit: (data) => {
      console.log("enrollment report", data);
      setCurrentPage(1);
      getReportData(data, 1);
      setFilterData(data);
    },
  });
  const isSearchFormFieldValid = (name) =>
    !!(searchFormik.touched[name] && searchFormik.errors[name]);
  const getSearchFormErrorMessage = (name) => {
    return (
      isSearchFormFieldValid(name) && (
        <small className="report-error-field">
          {searchFormik.errors[name]}
        </small>
      )
    );
  };

  const areAllValuesBlank = () => {
    const { mobile, status, startDate, endDate } = searchFormik.values;
    return !mobile && !status && !startDate && !endDate;
  };

  const getReportData = async (data, pageNum) => {
    setLoading(true);
    if (!token) {
      navigate("/");
    } else {
      // const searchMobile = data.mobile === "" ? "" : `&mobile=${data.mobile}`;
      // const searchStatus = data.status === "" ? "" : `&status=${data.status}`;

      const searchMobile =
        data?.mobile !== undefined && data?.mobile !== ""
          ? `&mobile=${data?.mobile}`
          : "";
      const searchStatus =
        data?.status !== undefined && data?.status !== ""
          ? `&status=${data?.status}`
          : "";

      let stDate;
      let endDate;

      var momentObj1 = moment(data.startDate);
      var momentObj2 = moment(data.endDate);
      if (data.startDate && data.endDate) {
        var startFormattedDate = momentObj1.format("YYYY-MM-DD HH:mm:ss");
        var startFormattedDate4 = momentObj2.format("YYYY-MM-DD 23:59:59");
        stDate = `&start=${startFormattedDate}`;
        endDate = `&end=${startFormattedDate4}`;
      } else {
        stDate = "";
        endDate = "";
      }

      await axios
        .get(
          Constants.baseUrl +
            `reports/program3/redemptionList/list?sortBy=createDateTime&sortAsc=true${searchMobile}${searchStatus}${stDate}${endDate}&limit=${limit}&page=${pageNum}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
          }
        )
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success") {
            setReportData(dt?.output?.results);
            setTotalPages(dt?.output?.totalPages);
            setLoading(false);
          }
          if (dt.result === "error" || dt.result === "dialog") {
            setReportData(null);
            setTotalPages(0);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const download = (data) => {
    setLoading(true);
    setDownloadLoading(true);
    try {
      // const searchMobile = data.mobile === "" ? "" : `&mobile=${data.mobile}`;
      // const searchStatus = data.status === "" ? "" : `&status=${data.status}`;
      // let stDate;
      // let endDate;

      // var momentObj1 = moment(data.startDate);
      // var momentObj2 = moment(data.endDate);
      // if (data.startDate && data.endDate) {
      //   var startFormattedDate = momentObj1.format("YYYY-MM-DD HH:mm:ss");
      //   var startFormattedDate4 = momentObj2.format("YYYY-MM-DD 23:59:59");
      //   stDate = `&start=${startFormattedDate}`;
      //   endDate = `&end=${startFormattedDate4}`;
      // } else {
      //   stDate = "";
      //   endDate = "";
      // }

			let searchD = "?";

      searchD += data.mobile !== "" ? `mobile=${data.mobile}&` : "";
      searchD += data.status !== "" ? `status=${data.status}&` : "";

      if (data.startDate && data.endDate) {
        var momentObj1 = moment(data.startDate);
        var momentObj2 = moment(data.endDate);

        var startFormattedDate = momentObj1.format("YYYY-MM-DD 00:00:00");
        var endFormattedDate = momentObj2.format("YYYY-MM-DD 23:59:59");

        searchD += `start=${startFormattedDate}&`;
        searchD += `end=${endFormattedDate}&`;
      }

      searchD = searchD.endsWith("&") ? searchD.slice(0, -1) : searchD;

      axios
        .get(
          Constants.baseUrl +
            `reports/program3/download/redemptionListReport${searchD}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
            responseType: "blob", // Set the responseType to 'blob'
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            const fileData = new Blob([res.data], { type: "text/csv" });
            saveAs(fileData, "RedemptionListReport.csv");
            setDownloadLoading(false);
          } else {
            swal({
              text: res.data.msg,
              // icon: 'error',
              timer: 2000,
              buttons: false,
            });
            setDownloadLoading(false);
          }
        });
    } catch (error) {
      console.log(error);
      setDownloadLoading(false);
    }
  };

  //console.log("reportDatareportData", reportData);
  return (
    <>
		  {loading ? <Oval /> : null}
      <Header />
      <section class="blog-area pt182">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="all-single-blog rpt">
								<form onSubmit={searchFormik.handleSubmit}>
                  <div class="row clearfix callwell mb-15">
                    <div class="col-md-3 col-sm-12 col-xs-12">
                      <div class="form-group has-float-label style-two">
                        <label>Mobile No.</label>
                        <input
                          class="form-control"
                          placeholder="Mobile No."
                          type="text"
                          id="mobile"
                          value={searchFormik.values.mobile}
                          onChange={searchFormik.handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <label>From Date </label>
                      <DatePicker
                        id="startDate"
                        selected={searchFormik.values.startDate}
                        onChange={(date) =>
                          searchFormik.setFieldValue("startDate", date)
                        }
                        dateFormat="dd-MM-yyyy"
                        className="form-control"
                        showYearDropdown
                        showMonthDropdown
                        autoComplete="off"
                        scrollableYearDropdown
                        maxDate={new Date()}
                        onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                        onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                        onClick={(e) => e.preventDefault()} // Preven
                        placeholderText="From date"
                      />
                      {getSearchFormErrorMessage("startDate")}
                    </div>
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <label>To Date </label>&nbsp;&nbsp;&nbsp;
                      <DatePicker
                        id="endDate"
                        selected={searchFormik.values.endDate}
                        onChange={(date) =>
                          searchFormik.setFieldValue("endDate", date)
                        }
                        dateFormat="dd-MM-yyyy"
                        className="form-control"
                        showYearDropdown
                        showMonthDropdown
                        autoComplete="off"
                        scrollableYearDropdown
                        maxDate={new Date()}
                        onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                        onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                        onClick={(e) => e.preventDefault()} // Preven
                        placeholderText="To date"
                      />
                      {getSearchFormErrorMessage("endDate")}
                    </div>
                    <div className="col-md-2 col-sm-12 col-xs-12">
                      <label>Status</label>
                      <select
                        className="form-control2"
                        id="status"
                        value={searchFormik.values.status}
                        onChange={searchFormik.handleChange}
                      >
                        <option value="">Select Status</option>
                        <option value="DELIVERED">DELIVERED</option>
                        <option value="ORDERED">ORDERED</option>
                        <option value="REJECT">REJECT</option>
												<option value="ON_HOLD">ON_HOLD</option>
                      </select>
                      {getSearchFormErrorMessage("status")}
                    </div>
                    <div class="col-md-1 col-sm-12 col-xs-12">
                      <div class="form-group has-float-label style-two">
                        <label class="invisible" style={{ width: "100%" }}>
                          hidden
                        </label>
                        <button
                          type="submit"
                          class="btn login_btn"
                          //disabled={areAllValuesBlank()}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

                <div class="row">
                  <div class="col-md-12">
                    <div class="carrd1">
                      <div class="card-header card-header-warning">
												<h4 class="card-title">
													Redemption Report
                          {/* <a href="#" class="pull-right"><i class="fa fa-file-excel-o"></i> Export</a> */}
                          <Link
                            to="#"
                            class={`pull-right ${
                              downloadLoading ? "btn-opacity" : ""
                            }`}
                            onClick={() => download(searchFormik.values)}
                          >
                            {downloadLoading ? (
                              <span>
                                {" "}
                                <i class="fa fa-spinner fa-spin"></i>Export
                              </span>
                            ) : (
                              <span>
                                {" "}
                                <i class="fa fa-file-excel-o"></i>Export
                              </span>
                            )}
                          </Link>
                        </h4>
                      </div>

                      <div class="card-body table-responsive">
                        <table class="table table-striped mb_01">
                          <thead>
                            <tr>
                              <th>S.No.</th>
															<th>UniqueId</th>
															<th>Name</th>
															<th>mobile</th>
															<th>ResidenceRegion</th>
															<th>ResidenceState</th>
															<th>ResidenceCity</th>
															<th>ResidencePincode</th>
															<th>OrganizationName</th>
															<th>OfficeRegion</th>
															<th>OfficeState</th>
															<th>OfficeCity</th>
															<th>OfficePincode</th>
															<th>redeemptionId</th>
															<th>status</th>
															<th>RedeemPoints</th>
															<th>RedemptionType</th>
															<th>UPIId</th>
															<th>totalEarnedPoints</th>
															<th>totalRedeemedPoints</th>
															<th>RedemptionRequestDate</th>
															<th>RedemptionProcessedDate</th>
                            </tr>
                          </thead>
                          {reportData === null ? (
                            <tbody>
                              <tr>
                                <td
                                  colSpan="32"
                                  style={{
                                    textAlign: "center",
                                    fontSize: "16px",
                                  }}
                                >
                                  No record found!
                                </td>
                              </tr>
                            </tbody>
                          ) : (
                            <tbody>
                              {reportData &&
                                reportData.map((item, index) => {
                                  const serialNumber =
                                    (currentPage - 1) * limit + index + 1;


                                  const RequestDate = item[19]
                                    ? moment(item[19]).format("DD-MM-YYYY")
                                    : "";
																	
																		const ProcessedDate = item[20]
                                    ? moment(item[20]).format("DD-MM-YYYY")
                                    : "";
                                  return (
                                    <>
                                      <tr>
                                        <td>{serialNumber}</td>
                                        <td>{item[0]}</td>
                                        <td>{item[1]}</td>
                                        <td>{item[2]}</td>
                                        <td>{item[3]}</td>
                                        <td>{item[4]}</td>
                                        <td>{item[5]}</td>
                                        <td>{item[6]}</td>
                                        <td>{item[7]}</td>
                                        <td>{item[8]}</td>
                                        <td>{item[9]}</td>
                                        <td>{item[10]}</td>
                                        <td>{item[11]}</td>
                                        <td>{item[12]}</td>
                                        <td>{item[13]}</td>
                                        <td>{item[14]}</td>
                                        <td>{item[15]}</td>
                                        <td>{item[16]}</td>
                                        <td>{item[17]}</td>
                                        <td>{item[18]}</td>
                                        <td>{RequestDate}</td>
                                        <td>{ProcessedDate}</td>
                                      </tr>
                                    </>
                                  );
                                })}
                            </tbody>
                          )}
                        </table>
                        {totalPages > 1 && (
                          <ResponsivePagination
                            current={currentPage}
                            total={totalPages}
                            onPageChange={(page) => {
                              setCurrentPage(page);
                              getReportData(filterData, page)
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
